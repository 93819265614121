import React from "react";
import Img_LogoutMenu from "./img/LogoutMenu.png";

function Logout(props) {
  return (
    <>
      <h3>Logga ut</h3>
      <p>
        För att logga ut från bokningssystemet går du till menyn och väljer{" "}
        <em>Logout</em>.
      </p>
      <img src={Img_LogoutMenu} alt="Meny: Logout" />
      <p>
        Du kommer då att bli utloggad och du måste logga in igen om du vill boka
        tider. Om du använder en publik dator rekomenderar vi att du loggar ut
        från bokningssystemet när du har använt det klart.
      </p>
    </>
  );
}
export default Logout;
