import { handleResponse, handleError } from "./apiUtils";
//const baseUrl = process.env.REACT_APP_API_URL + "/timeslots/";
const baseUrl =
  "https://uj9bq815f8.execute-api.eu-north-1.amazonaws.com/activitytypes";

export function getActivityTypes(instance) {
  const url = baseUrl + "/" + instance;
  return fetch(url).then(handleResponse).catch(handleError);
}

export function saveActivityTypes(activityTypes) {
  return fetch(baseUrl, {
    method: "PUT",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(activityTypes),
  })
    .then(handleResponse)
    .catch(handleError);
}
