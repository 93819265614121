import React from "react";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import PropTypes from "prop-types";

function BookedButton(props) {
  let bookedBy = props.bookedBy;
  if (props.userLoginStatus !== "LOGGED_IN") {
    //If the user is not logged in delete the phone number to not show it on any public page.
    bookedBy = props.bookedBy.replace(/\d/g, "");
  }
  return (
    <Button
      variant="danger"
      size="sm"
      key={props.id}
      title={bookedBy ? "Bokad av " + bookedBy : ""}
      onClick={function () {
        if (props.userLoginStatus === "LOGGED_IN") {
          props.setSlotId({
            date: props.date,
            time: props.time,
            id: props.id,
            name: props.name,
            slotNr: props.slotNr,
            bookedTimestamp: props.bookedTimestamp,
            bookedBy: props.bookedBy || "",
            updatedBy: props.updatedBy || "",
            modalMode: "UPDATE", // Decides if the Modal shall be rendered for a new booking or update an existing booking
          });
          props.handleShowModal();
        } else {
          toast.warn("Du måste vara inloggad för att kunna ändra en bokning.");
        }
      }}
    >
      {props.name}
    </Button>
  );
}
BookedButton.propTypes = {
  id: PropTypes.string.isRequired,
  userLoginStatus: PropTypes.string,
  date: PropTypes.string.isRequired,
  time: PropTypes.number.isRequired,
  slotNr: PropTypes.number.isRequired,
  bookedBy: PropTypes.string.isRequired,
  updatedBy: PropTypes.string,
  bookedTimestamp: PropTypes.string.isRequired,
  setSlotId: PropTypes.func.isRequired,
  handleShowModal: PropTypes.func.isRequired,
};
export default BookedButton;
