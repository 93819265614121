import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

function Register(props) {
  if (props.userData.status === "LOGGED_IN") {
    return null;
  } else
    return (
      <p>
        För att kunna boka måste du vara{" "}
        <Link to={"/newuser"}>registrerad</Link> och{" "}
        <Link to={"/login"}>inlogggad</Link>.
      </p>
    );
}
Register.propTypes = {
  userData: PropTypes.object.isRequired,
};
export default Register;
