import { getNowDate, addDaysToDate } from "../lib/dateFunctions";

import { handleResponse, handleError } from "./apiUtils";
const baseUrl = "https://uj9bq815f8.execute-api.eu-north-1.amazonaws.com/slots";

export function getSlots(instance) {
  const url = baseUrl + "/" + instance;
  return fetch(url).then(handleResponse).catch(handleError);
}

export function getSlotsFromDate(instance) {
  const date = addDaysToDate(getNowDate(), -21); // Get the slots from 3 weeks ago and further
  const url = baseUrl + "/" + instance + "/" + date;
  return fetch(url).then(handleResponse).catch(handleError);
}

export function getAllSlots() {
  const url = baseUrl;
  return fetch(url).then(handleResponse).catch(handleError);
}

export function saveSlot(slot) {
  return fetch(baseUrl, {
    method: "POST",
    //  mode: "no-cors",
    headers: { "content-type": "application/json" },
    body: JSON.stringify({ ...slot }),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function updateSlot(slot) {
  return fetch(baseUrl, {
    method: "PUT",
    //  mode: "no-cors",
    headers: { "content-type": "application/json" },
    body: JSON.stringify({ ...slot }),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function deleteSlot(props) {
  const url =
    baseUrl + "/" + props.instance + "/" + encodeURIComponent(props.id);
  return fetch(url, {
    method: "DELETE",
  })
    .then(handleResponse)
    .catch(handleError);
}
