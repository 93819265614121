import React from "react";
import InstanceSection from "./InstanceSection";
import PropTypes from "prop-types";

function BookingStatistics(props) {
  return (
    <>
      <h3>{props.year}</h3>
      <InstanceSection
        configData={props.configData}
        slots={props.slots}
        year={props.year}
      />
    </>
  );
}
BookingStatistics.propTypes = {
  configData: PropTypes.array.isRequired,
  slots: PropTypes.array.isRequired,
  year: PropTypes.number.isRequired,
};
export default BookingStatistics;
