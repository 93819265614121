import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {
  getActivityTypes,
  saveActivityTypes,
} from "../../api/activityTypesApi";
import { toast } from "react-toastify";
import PropTypes from "prop-types";

const ActivityListModal = (props) => {
  const [activityTypes, setActivityTypes] = useState([]);
  const [newActivityType, setNewActivityType] = useState({
    activity: "",
    abbreviation: "",
    bgColour: "#000000",
    fontColour: "#FFFFFF",
    instance: props.configuration.instance,
  });
  const reloadFlag = true;
  useEffect(() => {
    if (props.configuration.instance) {
      getActivityTypes(props.configuration.instance).then((_activityTypes) => {
        setActivityTypes(_activityTypes.Items);
      });
    }
  }, [props.configuration.instance, reloadFlag]);

  function handleDelete(event) {
    const _activityTypes = activityTypes.filter(
      (i) => i.activity !== event.target.name
    );
    setActivityTypes(_activityTypes);
  }

  function handleColourChange(event) {
    const _activityTypes = activityTypes.map((i) =>
      i.activity === event.target.name
        ? { ...i, bgColour: event.target.value }
        : i
    );
    setActivityTypes(_activityTypes);
  }

  function handleFontColourChange(event) {
    const _activityTypes = activityTypes.map((i) =>
      i.activity === event.target.name
        ? {
            ...i,
            fontColour: i.fontColour === "#FFFFFF" ? "#000000" : "#FFFFFF",
          }
        : i
    );
    setActivityTypes(_activityTypes);
  }

  function handleNewFontColourChange(event) {
    const _newActivityType = {
      ...newActivityType,
      fontColour:
        newActivityType.fontColour === "#FFFFFF" ? "#000000" : "#FFFFFF",
    };
    setNewActivityType(_newActivityType);
  }

  function handleNewChange(event) {
    const _newActivityType = {
      ...newActivityType,
      [event.target.name]: event.target.value,
    };
    setNewActivityType(_newActivityType);
  }

  function handleAdd(event) {
    if (!newActivityType.activity) {
      toast.error("Aktivitet måste vara ifylld");
    } else if (!newActivityType.abbreviation) {
      toast.error("Förkortning måste vara ifylld");
    } else if (newActivityType.abbreviation.length > 2) {
      toast.error("Förkortning får innehålla max två bokstäver");
    } else {
      newActivityType.instance = props.configuration.instance;
      const _activityTypes = activityTypes;
      _activityTypes.push(newActivityType);
      _activityTypes.sort(function (a, b) {
        const activityA = a.activity.toUpperCase(); // ignore upper and lowercase
        const activityB = b.activity.toUpperCase(); // ignore upper and lowercase
        if (activityA < activityB) {
          return -1;
        }
        if (activityA > activityB) {
          return 1;
        }

        // names must be equal
        return 0;
      });
      setActivityTypes(_activityTypes);
      setNewActivityType({
        activity: "",
        abbreviation: "",
        bgColour: "#000000",
        fontColour: "#FFFFFF",
      });
    }
  }

  function handleSave() {
    saveActivityTypes(activityTypes).then((response) => {
      if (response.status === "SUCCESS") {
        toast.success("Aktiviteter är sparade.", {
          autoClose: 2000,
        });
        props.handleCloseModal();
      } else {
        toast.error(
          "Något gick fel när vi skulla spara aktiviteter. Försök gärna igen."
        );
      }
    });
  }

  return (
    <Modal show={props.showModal} onHide={props.handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>
          Aktivitetslista
          <h5>{props.configuration.name}</h5>
        </Modal.Title>
      </Modal.Header>
      <Form>
        <Modal.Body className="show-grid">
          <Container>
            {activityTypes.map((i) => (
              <div key={i.activity}>
                <Row>
                  <Col xs={4}>
                    <Form.Label>{i.activity}</Form.Label>
                  </Col>
                  <Col xs={2}>
                    <Form.Label>{i.abbreviation}</Form.Label>
                  </Col>
                  <Col xs={1}>
                    <Form.Check
                      inline
                      name={i.activity}
                      checked={i.fontColour === "#FFFFFF" ? false : true}
                      onChange={handleFontColourChange}
                    />
                  </Col>
                  <Col xs={2}>
                    <Form.Control
                      type="color"
                      id={i.activity}
                      name={i.activity}
                      defaultValue={i.bgColour}
                      title="Välj bakgrundsfärg"
                      onChange={handleColourChange}
                    />
                  </Col>
                  <Col xs={2}>
                    <div
                      className="activity-type"
                      style={{
                        backgroundColor: i.bgColour,
                        color: i.fontColour,
                      }}
                    >
                      <span title={i.activity}>{i.abbreviation}</span>
                    </div>
                  </Col>
                  <Col xs={1}>
                    <Button
                      variant="danger"
                      className="rounded-circle"
                      size="sm"
                      name={i.activity}
                      onClick={handleDelete}
                    >
                      X
                    </Button>
                  </Col>
                </Row>
              </div>
            ))}
            <Row>
              <Col xs={4}>
                <Form.Control
                  type="input"
                  placeholder="Aktivitet"
                  name="activity"
                  value={newActivityType.activity}
                  onChange={handleNewChange}
                />
              </Col>
              <Col xs={2}>
                <Form.Control
                  type="input"
                  placeholder="?"
                  name="abbreviation"
                  value={newActivityType.abbreviation}
                  onChange={handleNewChange}
                />
              </Col>
              <Col xs={1}>
                <Form.Check
                  inline
                  checked={
                    newActivityType.fontColour === "#FFFFFF" ? false : true
                  }
                  onChange={handleNewFontColourChange}
                />
              </Col>
              <Col xs={2}>
                <Form.Control
                  type="color"
                  name="bgColour"
                  defaultValue="#000000"
                  title="Välj bakgrundsfärg"
                  onChange={handleNewChange}
                />
              </Col>
              <Col xs={2}>
                <div
                  className="activity-type"
                  style={{
                    backgroundColor: newActivityType.bgColour,
                    color: newActivityType.fontColour,
                  }}
                >
                  <span title={newActivityType.activity}>
                    {newActivityType.abbreviation || "?"}
                  </span>
                </div>
              </Col>
              <Col xs={1}>
                <Button
                  variant="success"
                  className="rounded-circle"
                  size="sm"
                  name="add"
                  onClick={handleAdd}
                >
                  +
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Form>
      <Modal.Footer>
        <Button variant="outline-dark" onClick={handleSave}>
          Spara
        </Button>
        <Button variant="secondary" onClick={props.handleCloseModal}>
          Avbryt
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
ActivityListModal.propTypes = {
  configuration: PropTypes.object.isRequired,
  showModal: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
};
export default ActivityListModal;
