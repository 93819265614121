import React from "react";
import PropTypes from "prop-types";

function Comment(props) {
  if (props.comment) {
    const trimmedComment = props.comment.substring(0, 90);
    return (
      <div className="activity-comment" title={props.comment}>
        {trimmedComment}
      </div>
    );
  } else {
    return null;
  }
}
Comment.propTypes = {
  comment: PropTypes.string,
};
export default Comment;
