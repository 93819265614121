import { handleResponse, handleError } from "./apiUtils";
const baseUrl = "https://uj9bq815f8.execute-api.eu-north-1.amazonaws.com/user";

export function getUsers() {
  const url = baseUrl;
  return fetch(url).then(handleResponse).catch(handleError);
}

export function getUser(userid) {
  const url = baseUrl + "/" + userid;
  return fetch(url).then(handleResponse).catch(handleError);
}

export function saveUser(user) {
  return fetch(baseUrl, {
    method: "POST",
    //  mode: "no-cors",
    headers: { "content-type": "application/json" },
    body: JSON.stringify({ ...user }),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function updateUser(user) {
  return fetch(baseUrl, {
    method: "PUT",
    //  mode: "no-cors",
    headers: { "content-type": "application/json" },
    body: JSON.stringify({ ...user }),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function deleteUser(props) {
  const url = baseUrl + "/" + encodeURIComponent(props.userid);
  return fetch(url, {
    method: "DELETE",
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function checkUserLogin(props) {
  const url = baseUrl + "/login/" + props.user + "/" + props.email;
  return fetch(url).then(handleResponse).catch(handleError);
}
