import React from "react";
import Img_Outlook from "./img/Outlook.png";
import Img_Google1 from "./img/Google1.png";
import Img_Google2 from "./img/Google2.png";
import PropTypes from "prop-types";

function ExternalCalendars(props) {
  return (
    <>
      <h3>Synkronisering av externa kalendrar</h3>
      <p>
        Du kan synkronisera externa kalendrar såsom Microsoft Outlook, Google
        kalender eller Apple kalender. Du kan då se dina bokningar i respektive
        kalender.
      </p>
      <p>
        Synkronisering av kalendrar använder en mekanism som heter ICAL eller
        ICS. För att synkronisera behöver du en ICAL-länk och du ser din nedan.
        Du kopierar den länken och klistrar in i epektive program. Neda finns
        beskrivning av hur du gör för några vanliga kalendrar.
        <br />
        <br />
        {props.userid ? (
          <p>
            <strong>ICAL-länk:</strong>&nbsp;
            https://uj9bq815f8.execute-api.eu-north-1.amazonaws.com/ical/
            {props.userid}
          </p>
        ) : null}
      </p>
      <h4>Microsoft Outlook</h4>
      <p>
        För att lägga till en extern kalender i Microsoft Outlook går du till{" "}
        <em>Add Calendar</em> och sen <em>From Internet</em>. En dialogruta
        visas och i den klistrar du in den kopierade ICAL-länken ovan.
      </p>
      <img src={Img_Outlook} alt="Outlook" />
      <h4>Google kalender</h4>
      <p>
        För att lägga in en extern kalender i Google kalender går du in på din
        Google kalender på webben. Där väljer du <em>+</em> under{" "}
        <em>Andra kalendrar</em> och därefter <em>Prenumerera på kalendern.</em>
      </p>
      <img src={Img_Google1} alt="Google" />
      <p>
        På sidan <em>Inställningar</em> som kommer upp väljer du{" "}
        <em>Från webbadress</em> under <em>Lägg till kalender</em>. Klistra sen
        in ICAL-länken ovan i fältet <em>Kalenderns webadress</em>.
      </p>
      <img src={Img_Google2} alt="Google" />
      <h4>Apple kalender</h4>
      <p>
        Om du vill prenumerera på kalendern i din Apple kalender finns här en
        beskrivning hur du gör.
      </p>
      <ol>
        <li>
          Välj Arkiv &gt; Ny kalenderprenumeration i appen Kalender på datorn.
        </li>
        <li>
          Ange kalenderns webbadress genom att klistra in ICAL-länken ovan och
          klicka på Prenumerera.
        </li>
        <li>
          Ange ett namn på kalendern i fältet Namn och klicka sedan på
          popupmenyn intill och välj en färg.
        </li>
        <li>
          Klicka på popupmenyn Plats och välj ett konto för prenumerationen.
        </li>
        <ul>
          <li>
            Om du väljer ditt iCloud-konto blir kalendern tillgänglig på alla
            datorer och enheter som du ställt in för användning med iCloud.
          </li>
          <li>Om du väljer På min Mac sparas kalendern på datorn.</li>
        </ul>
        <li>
          Om du vill ta emot kalenderaktivitetens bilagor eller notiser
          avmarkerar du motsvarande ta bort-kryssrutor.
        </li>
        <li>
          Klicka på popupmenyn Autouppdatera och välj hur ofta kalendern ska
          uppdateras.
        </li>
        <li>
          Om du inte vill visa notiser för kalendern markerar du Ignorera
          notiser.
        </li>
        <li>Klicka på OK.</li>
      </ol>
      Du kan göra ändringar i kalendern senare genom att klicka på kalenderns
      namn och sedan välja Redigera &gt; Visa info.
    </>
  );
}
ExternalCalendars.propTypes = {
  userid: PropTypes.number,
};
export default ExternalCalendars;
