import React from "react";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";

function ActivityList(props) {
  return (
    <>
      <h5>Aktivitet</h5>
      <Form.Group className="mb-3" controlId="activity">
        {props.activityTypes.map((i) => (
          <Form.Check
            type="radio"
            id={i.activity}
            key={i.activity}
            name="activity"
            value={i.activity}
            label={i.activity}
            onChange={props.onChange}
            checked={props.activity === i.activity}
          />
        ))}
      </Form.Group>
    </>
  );
}
ActivityList.propTypes = {
  activityTypes: PropTypes.array.isRequired,
  activity: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
export default ActivityList;
