import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { updateUser } from "../../api/userApi";
import Header from "../common/Header";
import Footer from "../common/Footer";
import RegisterForm from "./RegisterForm";
import PropTypes from "prop-types";

function UpdateUser(props) {
  const [configuration, setConfiguration] = useState({});
  const [errors, setErrors] = useState({});
  const [newUser, setNewUser] = useState({
    user: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
  });

  // Update user
  useEffect(() => {
    const updatedUser = {
      userid: props.userData.userid || "",
      user: props.userData.user || "",
      firstName: props.userData.firstName || "",
      lastName: props.userData.lastName || "",
      phoneNumber: props.userData.phoneNumber || "",
      email: props.userData.email || "",
    };
    setNewUser(updatedUser);
  }, [
    props.userData.userid,
    props.userData.user,
    props.userData.firstName,
    props.userData.lastName,
    props.userData.phoneNumber,
    props.userData.email,
  ]);

  // Set configuration for the selected instance
  useEffect(() => {
    const instance = parseInt(props.instance, 10);
    const result = props.configData.filter((e) => e.instance === instance);
    let _configuration = {};
    if (result.length) {
      _configuration = result[0];
      setConfiguration(_configuration);
    }
  }, [props]);

  function handleChange({ target }) {
    setNewUser({
      ...newUser,
      [target.name]: target.value,
    });
  }

  function formIsValid() {
    const _errors = {};

    if (!newUser.user) _errors.user = "Du har glömt användarnamn";
    if (!newUser.firstName) _errors.firstName = "Du har glömt förnamn";
    if (!newUser.lastName) _errors.lastName = "Du har glömt efternamn";
    if (!newUser.email) _errors.email = "Du har glömt mailadress";
    if (!newUser.phoneNumber)
      _errors.phoneNumber = "Du har glömt telefonnummer";

    setErrors(_errors);
    // Form is valid if the errors object has no properties
    return Object.keys(_errors).length === 0;
  }

  function handleSubmit(event) {
    event.preventDefault();
    if (!formIsValid()) return;
    updateUser(newUser).then((response) => {
      if (response.status === "SUCCESS") {
        toast.success("Användare uppdaterad.", {
          autoClose: 2000,
        });
      } else {
        toast.error(
          "Något gick fel vid uppdatering av användare. Försök gärna igen."
        );
      }
    });
  }

  // User must be logged in to access the update user page
  if (typeof props.userData && props.userData.status !== "LOGGED_IN") {
    return null;
  }

  return (
    <div className="page-container">
      <Header
        headerTitle={configuration.instanceTitle + " Uppdatera användare"}
        userStatus={props.userData.status}
        userFirstName={props.userData.firstName}
        userAdmin={props.userData.admin}
        configData={props.configData}
      />
      <RegisterForm
        newUser={newUser}
        errors={errors}
        userid={props.userData.userid}
        onChange={handleChange}
        onSubmit={handleSubmit}
      />
      <Footer pageFooterText={configuration.instanceName || ""} />
    </div>
  );
}
UpdateUser.propTypes = {
  userData: PropTypes.object.isRequired,
  configData: PropTypes.array.isRequired,
};
export default UpdateUser;
