import React from "react";
import BookingStatisticsTable from "./BookingStatisticsTable";
import PropTypes from "prop-types";

function InstanceSection(props) {
  return props.configData.map((i) => {
    return (
      <div key={i.instance}>
        <h4>{i.name}</h4>
        <BookingStatisticsTable
          slots={props.slots}
          instance={i.instance}
          year={props.year}
          userid={props.userid}
        />
      </div>
    );
  });
}
InstanceSection.propTypes = {
  configData: PropTypes.array.isRequired,
  slots: PropTypes.array.isRequired,
  year: PropTypes.number.isRequired,
  userid: PropTypes.number.isRequired,
};
export default InstanceSection;
