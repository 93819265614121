import React from "react";
import Img_Timesheet from "./img/Timesheet.png";
import Img_Book from "./img/Book.png";
import Img_Update from "./img/Update.png";
import Img_OpenBookingDelete from "./img/OpenBookingDelete.png";

function Book(props) {
  return (
    <>
      <h3>Boka en tid</h3>
      <p>
        För att boka en tid går du till bokningssidan för den ridbana du vill
        boka. Du trycker på knappen <em>Boka</em> på den tid du vill boka. Det
        är bara bokningsbara tider som visas.
      </p>
      <img src={Img_Timesheet} alt="Boka tid" />
      <p>En dialogruta visas där du kan göra dina bokningsval.</p>
      <img src={Img_Book} alt="Boka tid" />
      <p>
        Tryck på <em>Boka</em> när du är klar.
      </p>
      <h3>Ändra eller avboka en tid.</h3>
      <p>
        Om du vill ändra eller avboka en tid så trycker du på den bokade hästens
        namn. (Den röda knappen.)
      </p>
      <p>
        EN dialogruta visas där du kan göra ändringar. När du är klar tryck på{" "}
        <em>Ändra</em>. För att avboka tiden tryck på <em>Avboka</em>.
      </p>
      <img src={Img_Update} alt="Uppdatera eller avboka en tid" />
      <h3>Avboka en öppen bokning.</h3>
      <p>
        För att avboka en öppen bokning trycker du på Ö symbolen. En dialogruta
        visas då och där kan du avboka den öppna bokningen. Om det finns hästar
        bokade på den öppna bokningen måste de avbokas först innan man kan boka
        av den öppna bokningen.
      </p>
      <img src={Img_OpenBookingDelete} alt="Avboka en öppen bokning" />
    </>
  );
}
export default Book;
