import React from "react";
import Table from "react-bootstrap/Table";
import { getMonthName, getYear } from "../../lib/dateFunctions";
import PropTypes from "prop-types";

function BookingStatisticsTable(props) {
  // Create a header row with month names
  let months = [];
  months.push("Namn");
  for (let i = 1; i <= 12; i++) {
    months.push(getMonthName(i));
  }
  const header = months.map((i) => {
    return <th key={i}>{i}</th>;
  });

  const bookingMonthArray = new Array(12);
  let nameListObj = {};
  for (let i = 0; i < bookingMonthArray.length; i++) {
    bookingMonthArray[i] = {};
  }
  for (let i = 0; i < props.slots.length; i++) {
    const obj = props.slots[i];
    const d = new Date(obj.date);
    const month = d.getMonth();
    if (
      parseInt(getYear(obj.date), 10) === props.year &&
      obj.instance === props.instance &&
      obj.bookedByUserid === props.userid
    ) {
      bookingMonthArray[month][obj.name] =
        (bookingMonthArray[month][obj.name] || 0) + 1;
      nameListObj[obj.name] = true;
    }
  }
  // Statistics for each name
  const tbody = Object.keys(nameListObj).map((name) => {
    let i = 0;
    const row = bookingMonthArray.map((obj) => {
      return <td key={name + i++}>{obj[name] || 0}</td>;
    });
    return (
      <tr key={name}>
        <td key={name}>{name}</td>
        {row}
      </tr>
    );
  });

  return (
    <>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>{header}</tr>
        </thead>
        <tbody>{tbody}</tbody>
      </Table>
    </>
  );
}
BookingStatisticsTable.propTypes = {
  slots: PropTypes.array.isRequired,
  year: PropTypes.number.isRequired,
  instance: PropTypes.number.isRequired,
  userid: PropTypes.number.isRequired,
};
export default BookingStatisticsTable;
