import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

function SelectionList(props) {
  return (
    <ul>
      {props.configData
        .sort((a, b) => a.instance - b.instance)
        .map((e) => {
          return (
            <li key={e.instance}>
              <Link to={"/book/" + e.instance} key={e.instance}>
                {"Boka " + e.name}
              </Link>
            </li>
          );
        })}
    </ul>
  );
}
SelectionList.propTypes = {
  configData: PropTypes.array.isRequired,
};
export default SelectionList;
