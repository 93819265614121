import React from "react";
import PropTypes from "prop-types";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import {
  getNowDate,
  getYear,
  getWeekNr,
  addDaysToDate,
} from "../../lib/dateFunctions";

function Header(props) {
  if (props.userStatus === "LOGGED_IN") {
    return (
      <Navbar variant="dark" bg="dark" expand="lg">
        <Container fluid>
          <Navbar.Brand href="#home">{props.headerTitle}</Navbar.Brand>
          <Navbar.Toggle aria-controls="navbar-dark-example" />
          <Navbar.Collapse id="navbar-dark-example">
            <Nav>
              <NavDropdown
                id="nav-dropdown-dark-example"
                title="Meny"
                menuVariant="dark"
              >
                <ListOfInstances configData={props.configData} />
                <NavDropdown.Divider />
                <NavDropdown.Item href="/userHistory">
                  Bokningshistorik
                </NavDropdown.Item>
                <NavDropdown.Item href="/updateUser">
                  Ändra användaruppgifter
                </NavDropdown.Item>
                <NavDropdown.Item href="/logout">Logout</NavDropdown.Item>
                <AdminSection userAdmin={props.userAdmin} />
                <NavDropdown.Divider />
                <NavDropdown.Item href="/updateUser">
                  {"Inloggad som " + props.userFirstName}
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="/help">Hjälp</NavDropdown.Item>
              </NavDropdown>
            </Nav>
            <WeekSelector
              selectedDate={props.selectedDate}
              setSelectedDate={props.setSelectedDate}
            />
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  } else {
    return (
      <Navbar variant="dark" bg="dark" expand="lg">
        <Container fluid>
          <Navbar.Brand href="#home">{props.headerTitle}</Navbar.Brand>
          <Navbar.Toggle aria-controls="navbar-dark-example" />
          <Navbar.Collapse id="navbar-dark-example">
            <Nav>
              <NavDropdown
                id="nav-dropdown-dark-example"
                title="Login"
                menuVariant="dark"
              >
                <ListOfInstances configData={props.configData} />
                <NavDropdown.Divider />
                <NavDropdown.Item href="/login">Login</NavDropdown.Item>
                <NavDropdown.Item href="/newuser">
                  Registrera ny användare
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="/help">Hjälp</NavDropdown.Item>
              </NavDropdown>
            </Nav>
            <WeekSelector
              selectedDate={props.selectedDate}
              setSelectedDate={props.setSelectedDate}
            />
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }
}
Header.propTypes = {
  configData: PropTypes.array.isRequired,
  userStatus: PropTypes.string,
  headerTitle: PropTypes.string.isRequired,
  userFirstName: PropTypes.string,
  userAdmin: PropTypes.bool,
  selectedDate: PropTypes.string,
  setSelectedDate: PropTypes.func,
};
export default Header;

function AdminSection(props) {
  if (props.userAdmin) {
    return (
      <>
        <NavDropdown.Divider />
        <NavDropdown.Item href="/admin/bookingStatistics">
          Admin Bokningsstatistik
        </NavDropdown.Item>
        <NavDropdown.Item href="/admin/configuration">
          Admin Inställningar
        </NavDropdown.Item>
        <NavDropdown.Item href="/admin/users">
          Admin Visa användare
        </NavDropdown.Item>
      </>
    );
  } else {
    return null;
  }
}

function WeekSelector(props) {
  const year = getYear(props.selectedDate);
  const weekNr = getWeekNr(props.selectedDate);
  const nextWeekDate = addDaysToDate(props.selectedDate, 7);
  const previousWeekDate = addDaysToDate(props.selectedDate, -7);

  let onClick = null;
  if (props.selectedDate > addDaysToDate(getNowDate(), -14)) {
    onClick = () => props.setSelectedDate(previousWeekDate);
  }

  if (typeof props.selectedDate !== "undefined") {
    return (
      <Nav className="ml-auto">
        <Navbar.Text>
          <Button variant="dark" size="sm" onClick={onClick}>
            &lt;&lt;
          </Button>
          {year + " Vecka " + weekNr}
          <Button
            variant="dark"
            size="sm"
            onClick={function () {
              props.setSelectedDate(nextWeekDate);
            }}
          >
            &gt;&gt;
          </Button>
        </Navbar.Text>
      </Nav>
    );
  } else {
    return null;
  }
}

function ListOfInstances(props) {
  return props.configData
    .sort((a, b) => a.instance - b.instance)
    .map((e) => {
      return (
        <NavDropdown.Item href={"/book/" + e.instance} key={e.instance}>
          {"Bokning " + e.name}
        </NavDropdown.Item>
      );
    });
}
