import { toast } from "react-toastify";
export async function handleResponse(response) {
  if (response.ok) return response.json();
  if (response.status === 400) {
    // So, a server-side validation error occurred.
    // Server side validation returns a string error message, so parse as text instead of json.

    const error = await response.text();
    throw new Error(error);
  }
}

// In a real app, would likely call an error logging service.
export function handleError(error) {
  // eslint-disable-next-line no-console
  console.error("API call failed. " + error);
  toast.error(
    "Oooops! Det blev ett fel när vi försökte hämta eller spara data! Försök gärna igen.",
    {
      autoClose: 6000,
      toastId: "API_ERROR",
    }
  );
  throw error;
}
