import React from "react";
import PropTypes from "prop-types";

function Footer(props) {
  return (
    <div className="container">
      <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
        <p className="col-md-4 mb-0 text-muted">
          &copy; {props.pageFooterText}
        </p>
      </footer>
    </div>
  );
}
Footer.propTypes = {
  pageFooterText: PropTypes.string.isRequired,
};
export default Footer;
