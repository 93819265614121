const { add, format, parse } = require("date-fns");
const { utcToZonedTime } = require("date-fns-tz");
//const sv = require("date-fns/locale/sv");

function getTimestamp() {
  const timeZone = "Europe/Stockholm";
  const timeInSe = utcToZonedTime(new Date(), timeZone);
  //const timestamp = format(timeInSe, "T", { locale: sv });
  const timestamp = format(timeInSe, "T");
  return parseInt(timestamp, 10);
}

/*
function expired(timestamp) {
    const fnsDate = parse(timestamp,'T',new Date());
    const expireDate = add(new Date(), {days: -60});
    if (fnsDate-expireDate > 0) {
        return false;
    } else {
        return true;
    }
}
*/

function addNameToNameList(nameArray, newName) {
  if (newName === "" || newName === undefined) {
    return nameArray;
  }
  const timestamp = getTimestamp();
  // Check if name is already in the list
  let nameInList = false;
  for (let x = 0; x < nameArray.length; x++) {
    if (nameArray[x][0] === newName) {
      nameArray[x][1] = timestamp;
      nameInList = true;
    }
  }
  if (!nameInList) {
    nameArray.push([newName, timestamp]);
  }

  // Check if any name has expired (not been used within 60 days)
  const filtered = nameArray.filter(function (value, index, arr) {
    const timestamp = value[1];
    const fnsDate = parse(timestamp, "T", new Date());
    const expireDate = add(new Date(), { days: -60 });
    if (fnsDate - expireDate > 0) {
      return true;
    } else {
      return false;
    }
  });
  filtered.sort(function (a, b) {
    return b[1] - a[1];
  });
  if (filtered.length > 5) {
    filtered.splice(5, filtered.length);
  }
  return filtered;
}

module.exports = { addNameToNameList };
