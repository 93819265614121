import React from "react";
import BookingStatisticsTable from "./BookingStatisticsTable";
import PropTypes from "prop-types";

function InstanceSection(props) {
  return props.configData
    .sort((a, b) => a.instance - b.instance)
    .map((i) => {
      return (
        <div key={i.instance}>
          <h4>{i.name}</h4>
          <BookingStatisticsTable
            slots={props.slots}
            instance={i.instance}
            year={props.year}
          />
        </div>
      );
    });
}
InstanceSection.propTypes = {
  configData: PropTypes.array.isRequired,
  slots: PropTypes.array.isRequired,
  year: PropTypes.number.isRequired,
};
export default InstanceSection;
