import React from "react";
import { ButtonGroup } from "react-bootstrap";
import BookButton from "./BookButton";
import BookedButton from "./BookedButton";

function Buttons(props) {
  const bookedArray = props.slots // TODO!!!! Include sorting!!!!
    .filter(function (e) {
      return e.date === props.date;
    })
    .filter(function (e) {
      return e.time === props.time;
    });

  let slotNr = 1;
  if (bookedArray.length > 0) {
    let x = 0;
    let buttons = bookedArray.map((i) => (
      <BookedButton
        className="my-1"
        key={x++}
        name={i.name}
        id={i.id}
        date={props.date}
        time={props.time}
        slotNr={slotNr++}
        bookedTimestamp={i.bookedTimestamp}
        bookedBy={i.bookedBy}
        updatedBy={i.updatedBy}
        setSlotId={props.setSlotId}
        userLoginStatus={props.userLoginStatus}
        handleShowModal={props.handleShowModal}
        modalMode={"UPDATE"}
      />
    ));

    if (slotNr <= props.nrOfBookingsPerTimeSlot && props.bookable) {
      buttons.push(
        <BookButton
          className="my-1"
          key={x}
          id={props.date + ":" + props.time + ":" + slotNr}
          date={props.date}
          time={props.time}
          slotNr={slotNr}
          setSlotId={props.setSlotId}
          userLoginStatus={props.userLoginStatus}
          handleShowModal={props.handleShowModal}
          modalMode={"NEW"}
        />
      );
    }
    return <ButtonGroup vertical>{buttons}</ButtonGroup>;
  } else if (props.bookable) {
    const slotNr = bookedArray.length + 1;
    return (
      <BookButton
        id={props.date + ":" + props.time + ":" + slotNr}
        date={props.date}
        time={props.time}
        slotNr={slotNr}
        setSlotId={props.setSlotId}
        userLoginStatus={props.userLoginStatus}
        toggle={props.toggle}
        handleShowModal={props.handleShowModal}
      />
    );
  } else {
    return null;
  }
}

export default Buttons;
