import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { saveConfiguration } from "../../api/configurationApi";
import Header from "../common/Header";
import ConfigurationForm from "./ConfigurationForm";
import Footer from "../common/Footer";
import ActivityListModal from "./ActivityListModal";
import PropTypes from "prop-types";

function AdminConfigurationPage(props) {
  const pageFooterText = "Ullstämma ridbanebokningar";
  let headerTitle = "Konfigurering";
  const [selectOptionsArray, setSelectOptionsArray] = useState([]);
  const [configuration, setConfiguration] = useState({
    name: "",
    instanceTitle: "",
    instanceName: "",
    earliestTimeToBook: "",
    latestTimeToBook: "",
    nrOfBookingsPerTimeSlot: "",
  });
  const [errors, setErrors] = useState({});

  // Hooks for handling activity modal
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  // Set configuration for the selected instance
  useEffect(() => {
    const array = props.configData.sort((a, b) => a.instance - b.instance);
    if (array.length > 0) {
      setConfiguration(array[0]);
    }
  }, [props]);

  // Effect to populate the selection array for the instance selection in the Form
  useEffect(() => {
    const arr = props.configData
      .sort((a, b) => a.instance - b.instance)
      .map((e) => {
        return (
          <option value={e.instance} key={e.instance}>
            {e.instance} - {e.name}
          </option>
        );
      });
    const mewInstanceNr = props.configData.length + 1;
    arr.push(
      <option value={mewInstanceNr} key={mewInstanceNr}>
        {mewInstanceNr} - Ny
      </option>
    );
    setSelectOptionsArray(arr);
  }, [props]);

  function handleSelectChange({ target }) {
    const selectedInstance = parseInt(target.value, 10);
    const result = props.configData.filter(
      (e) => e.instance === selectedInstance
    );
    const mewInstanceNr = props.configData.length + 1;
    let _configuration = {
      instance: mewInstanceNr,
      name: "",
      instanceTitle: "",
      instanceName: "",
      earliestTimeToBook: "",
      latestTimeToBook: "",
      nrOfBookingsPerTimeSlot: "",
    };
    if (result.length) {
      _configuration = {
        instance: result[0].instance,
        name: result[0].name,
        instanceTitle: result[0].instanceTitle,
        instanceName: result[0].instanceName,
        earliestTimeToBook: result[0].earliestTimeToBook,
        latestTimeToBook: result[0].latestTimeToBook,
        nrOfBookingsPerTimeSlot: result[0].nrOfBookingsPerTimeSlot,
      };
    }
    setConfiguration(_configuration);
    setErrors({});
  }

  function handleChange({ target }) {
    setConfiguration({
      ...configuration,
      [target.name]: target.value,
    });
  }

  function formIsValid() {
    const _errors = {};

    if (!configuration.name) _errors.name = "Du har glömt namn";
    if (!configuration.instanceTitle)
      _errors.instanceTitle = "Du har glömt titel";
    if (!configuration.instanceName)
      _errors.instanceName = "Du har glömt instansnamn";
    if (
      parseInt(configuration.earliestTimeToBook, 10) >
      parseInt(configuration.latestTimeToBook, 10)
    ) {
      _errors.earliestTimeToBook =
        "Tidigaste tid att boka får inte vara senare än senaste tid att boka.";
      _errors.latestTimeToBook =
        "Tidigaste tid att boka får inte vara senare än senaste tid att boka.";
    }
    if (
      parseInt(configuration.earliestTimeToBook, 10) < 0 ||
      parseInt(configuration.earliestTimeToBook, 10) > 23
    )
      _errors.earliestTimeToBook = "Klockslaget måste vara mellan 0 - 23";
    if (!configuration.earliestTimeToBook)
      _errors.earliestTimeToBook = "Du har glömt tidigaste tid att boka";
    if (
      parseInt(configuration.latestTimeToBook, 10) < 0 ||
      parseInt(configuration.latestTimeToBook, 10) > 23
    )
      _errors.latestTimeToBook = "Klockslaget måste vara mellan 0 - 23";
    if (!configuration.latestTimeToBook)
      _errors.latestTimeToBook = "Du har glömt senaste tid att boka";
    if (
      parseInt(configuration.nrOfBookingsPerTimeSlot, 10) < 1 ||
      parseInt(configuration.nrOfBookingsPerTimeSlot, 10) > 10
    )
      _errors.nrOfBookingsPerTimeSlot =
        "Antal bokningar per tid måste vara mellan 1 - 10";
    if (!configuration.nrOfBookingsPerTimeSlot)
      _errors.nrOfBookingsPerTimeSlot =
        "Du har glömt max antal bokningar per tid";

    setErrors(_errors);
    // Form is valid if the errors object has no properties
    return Object.keys(_errors).length === 0;
  }

  function handleSubmit(event) {
    event.preventDefault();
    if (!formIsValid()) return;
    saveConfiguration(configuration).then((response) => {
      if (response.status === "SUCCESS") {
        toast.success("Data är sparad.", {
          autoClose: 2000,
        });
        return null;
      } else {
        toast.error(
          "Något gick fel när vi skulla spara data. Försök gärna igen."
        );
      }
    });
  }

  if (!props.userData.admin) return null; //Block the page if you are not admin

  return (
    <div className="page-container">
      <Header
        headerTitle={
          configuration.instanceTitle + " Inställningar" || headerTitle
        }
        userStatus={props.userData.status}
        userFirstName={props.userData.firstName}
        userAdmin={props.userData.admin}
        configData={props.configData}
      />
      <ConfigurationForm
        errors={errors}
        userid={props.userData.userid}
        configuration={configuration}
        selectOptionsArray={selectOptionsArray}
        onSelectChange={handleSelectChange}
        onChange={handleChange}
        onSubmit={handleSubmit}
        onShowActivityModal={handleShowModal}
      />
      <Footer pageFooterText={configuration.instanceName || pageFooterText} />
      <ActivityListModal
        configuration={configuration}
        showModal={showModal}
        handleCloseModal={handleCloseModal}
      />
    </div>
  );
}

AdminConfigurationPage.propTypes = {
  userData: PropTypes.object.isRequired,
  configData: PropTypes.array.isRequired,
};
export default AdminConfigurationPage;
