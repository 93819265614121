import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Header from "../common/Header";
import Footer from "../common/Footer";
import Container from "react-bootstrap/Container";
import PropTypes from "prop-types";
import SelectHelpPage from "./SelectHelpPage";
import TableOfContents from "./TableOfContents";

function HelpPage(props) {
  const params = useParams();
  const helpPage = params.helpPage;
  const [configuration, setConfiguration] = useState({});

  // Set configuration for the selected instance
  useEffect(() => {
    const instance = parseInt(props.instance, 10);
    const result = props.configData.filter((e) => e.instance === instance);
    let _configuration = {};
    if (result.length) {
      _configuration = result[0];
      setConfiguration(_configuration);
    }
  }, [props]);

  return (
    <>
      <Header
        headerTitle={
          configuration.instanceTitle
            ? configuration.instanceTitle + " Hjälp"
            : ""
        }
        userStatus={props.userData.status}
        userFirstName={props.userData.firstName}
        userAdmin={props.userData.admin}
        configData={props.configData}
      />
      <Container fluid="md">
        <h1>Hjälp</h1>
        <p>
          Det här är en bokningstjänst för boking av ribanor i Ullstämmastallen
          i Linköping. Här följer en beskrivning av hur du använder
          bokningssidan.
        </p>
        <TableOfContents userData={props.userData} />
        <hr></hr>
        <SelectHelpPage
          helpPage={helpPage || ""}
          userid={props.userData.userid}
        />
        <Footer pageFooterText={configuration.instanceName || ""} />
      </Container>
    </>
  );
}
HelpPage.propTypes = {
  userData: PropTypes.object.isRequired,
  configData: PropTypes.array.isRequired,
  instance: PropTypes.number.isRequired,
};
export default HelpPage;
