import React from "react";
import Img_RegisterNewUserMenu from "./img/RegisterNewUserMenu.png";

function RegisterUser() {
  return (
    <>
      <h3>Registrera användare</h3>
      <p>
        För att kunna boka tider på bokningssidorna måste du vara registrerad,
        detta för att vi vill kunna hålla reda på vem som bokar tider. Du
        registrerar en ny användare genom att välja{" "}
        <em>Registrera ny användare</em> i menyn.
      </p>
      <img src={Img_RegisterNewUserMenu} alt="Meny: Registrera ny användare" />
      <br />
      <br />
      <p>Du behöver ange följande uppgifter varav vissa är obligatoriska.</p>
      <h5>Användarnamn</h5>
      <p>
        Du behöver ange ett användarnamn. Det kan vara vad som helst men du
        behöver komma ihåg det för att kunna använda det när du loggar in.
        Användarnamnet känner skillnad på STORA och små bokstäver så kom ihåg
        vad du använder.
      </p>
      <h5>Epostadress</h5>
      <p>
        Skriv din epostadress. Epostadressen används för att identifiera dig vid
        inloggning. Vi kommer att skicka ett mail till den angivna epostadressen
        för att verifiera att den är din. Du behöver klicka på länken i mailet
        du får för att aktivera ditt konto.
      </p>
      <h5>Förnamn</h5>
      <p>
        Förnamn är inte obligatoriskt men det är rekomenderat att ange det så
        att vi kan visa ditt namn i samband med bokningar.
      </p>
      <h5>Efternamn</h5>
      <p>
        Efternamn är inte obligatoriskt men det är rekomenderat att ange det så
        att vi kan visa ditt namn i samband med bokningar.
      </p>
      <h5>Telefonnummer</h5>
      <p>
        Telefonnummer är inte obligatoriskt. Telefonnummer visas tillsamans med
        för och efternamn på den som har bokat tiden så att det är lätt att
        kontakta vid eventuella frågor. Telefonnummer visas bara för inlloggade
        personer, ej på några publika sidor. Om du vill hålla ditt telfonnummer
        hemligt behöver du inte ange telefonnummer.
      </p>
      <h3>Verifiering av epostadress</h3>
      <p>
        När du har sparat din registrering av ny användare skickar vi ett mail
        till den epostadress du angav. Du behöver gå in och läsa din epost och
        klicka på länken i mailet. Efter det är du aktiverad och du kan logga
        in.
      </p>
    </>
  );
}
export default RegisterUser;
