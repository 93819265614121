import React from "react";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { timestampToDate } from "../../lib/dateFunctions";
import PropTypes from "prop-types";

function UsersTable(props) {
  return (
    <Table striped bordered hover size="sm">
      <thead>
        <tr>
          <th>Användarnamn</th>
          <th>Förnamn</th>
          <th>Efternamn</th>
          <th>Epostadress</th>
          <th>Telefon</th>
          <th>Epostadress verifierad</th>
          <th>Box- ägare</th>
          <th>Admin- behörighet</th>
          <th>Användarid</th>
          <th>Kontot&nbsp;skapat</th>
          <th>Senast&nbsp;aktiv</th>
          <th>Ta bort användare</th>
        </tr>
      </thead>
      <tbody>
        {props.usersData
          .sort((a, b) => a.creationTime - b.creationTime)
          .map((i) => {
            return (
              <tr key={i.creationTime}>
                <td>{i.user}</td>
                <td>{i.firstName}</td>
                <td>{i.lastName}</td>
                <td>{i.email}</td>
                <td>{i.phoneNumber}</td>
                <td>{i.emailVerified ? "Ja" : "Nej"}</td>
                <td>
                  <Form.Check
                    inline
                    type="checkbox"
                    id="boxOwner"
                    name="boxOwner"
                    aria-label="boxägare"
                    value={i.userid}
                    checked={i.boxOwner || false}
                    onChange={props.handleChange}
                  />
                </td>
                <td>
                  <Form.Check
                    inline
                    type="checkbox"
                    id="admin"
                    name="admin"
                    value={i.userid}
                    aria-label="admin"
                    checked={i.admin || false}
                    onChange={props.handleChange}
                  />
                </td>
                <td>{i.userid}</td>

                <td>{timestampToDate(i.creationTime)}</td>
                <td>{timestampToDate(i.lastActive)}</td>
                <td>
                  <Button
                    variant="danger"
                    className="rounded-circle"
                    size="sm"
                    value={i.userid}
                    name={i.user}
                    onClick={props.handleDelete}
                  >
                    X
                  </Button>
                </td>
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
}
UsersTable.propTypes = {
  usersData: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};
export default UsersTable;
