import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Header from "../common/Header";
import Footer from "../common/Footer";
import BookingStatistics from "./BookingStatistics.js";
import { getAllSlots } from "../../api/slotsApi";
import { getNowObj } from "../../lib/dateFunctions";
import PropTypes from "prop-types";

function UserBookingHistoryPage(props) {
  const [slots, setSlots] = useState([]);
  const [configuration, setConfiguration] = useState({});

  useEffect(() => {
    getAllSlots().then((_slots) => {
      setSlots(_slots.Items);
    });
  }, []);

  // Set configuration for the selected instance
  useEffect(() => {
    const instance = parseInt(props.instance, 10);
    const result = props.configData.filter((e) => e.instance === instance);
    let _configuration = {};
    if (result.length) {
      _configuration = result[0];
      setConfiguration(_configuration);
    }
  }, [props]);

  const currentYear = parseInt(getNowObj().year, 10);

  // User must be logged in to show the page
  if (props.userData.status !== "LOGGED_IN") {
    //    return <h2>Du måste vara inloggad för att kunna visa sidan</h2>;
    return null;
  }
  return (
    <>
      <Header
        headerTitle={
          configuration.instanceTitle
            ? configuration.instanceTitle + " Bokningshistorik"
            : ""
        }
        userStatus={props.userData.status}
        userFirstName={props.userData.firstName}
        userAdmin={props.userData.admin}
        configData={props.configData}
      />
      <Container className="mb-3">
        <br />
        <BookingStatistics
          year={currentYear - 1}
          configData={props.configData}
          slots={slots}
          userid={props.userData.userid}
        />
        <br />
        <br />
        <BookingStatistics
          year={currentYear}
          configData={props.configData}
          slots={slots}
          userid={props.userData.userid}
        />
      </Container>
      <Footer pageFooterText={configuration.instanceName || ""} />
    </>
  );
}
UserBookingHistoryPage.propTypes = {
  userData: PropTypes.object.isRequired,
  configData: PropTypes.array.isRequired,
};
export default UserBookingHistoryPage;
