import React from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";

function Open(props) {
  if (props.open) {
    return (
      <div
        className="activity-special"
        onClick={function () {
          if (props.userLoginStatus === "LOGGED_IN") {
            const bookedArrayLength = props.slots // TODO!!!! Include sorting!!!!
              .filter(function (e) {
                return e.date === props.date;
              })
              .filter(function (e) {
                return e.time === props.time;
              }).length;
            if (bookedArrayLength > 0) {
              // There are bookings on this slot.
              toast.warn(
                "Du måste avboka alla hästar innan du kan ta bort en öppen bokning."
              );
            } else {
              props.setSlotId({
                date: props.date,
                time: props.time,
                modalMode: "NEW", // Decides if the Modal shall be rendered for a new booking or update an existing booking
              });
              props.handleShowOpenModal();
            }
          } else {
            toast.warn("Du måste vara inloggad för att boka en tid.");
          }
        }}
      >
        <span title="Öppen bokning">Ö</span>
      </div>
    );
  } else {
    return null;
  }
}
Open.propTypes = {
  date: PropTypes.string.isRequired,
  time: PropTypes.number.isRequired,
  slots: PropTypes.array.isRequired,
  open: PropTypes.bool,
  userLoginStatus: PropTypes.string.isRequired,
  setSlotId: PropTypes.func.isRequired,
  handleShowOpenModal: PropTypes.func.isRequired,
};
export default Open;
