import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { deleteTimeSlot } from "../../api/timeSlotsApi";
import { toast } from "react-toastify";
import PropTypes from "prop-types";

const OpenBookingDeleteModal = (props) => {
  function handleDelete() {
    const instance = parseInt(props.instance, 10);
    const bookedArrayLength = props.slots
      .filter(function (e) {
        return e.date === props.slotId.date;
      })
      .filter(function (e) {
        return e.time === props.slotId.time;
      }).length;
    if (bookedArrayLength > 0) {
      // There are bookings on this slot.
      toast.warn(
        "Du måste avboka alla hästar innan du kan ta bort en öppen bokning."
      );
    } else {
      // IF the timeslot is empty (noo hrses booed) then delete the timeslot
      const dateTime = props.slotId.date + ":" + props.slotId.time;
      deleteTimeSlot({ instance, dateTime }).then((response) => {
        props.reloadData();
      });
      toast.success("Tiden är avbokad.");
      props.handleCloseModal();
    }
  }
  return (
    <Modal show={props.showModal} onHide={props.handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>
          Avboka Öppen bokning
          <h6>{props.slotId.date}</h6>
          <h6>
            {String(props.slotId.time).padStart(2, "0") +
              ":00-" +
              String(props.slotId.time + 1).padStart(2, "0") +
              ":00"}
          </h6>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid"></Modal.Body>
      <Modal.Footer>
        <Button variant="outline-dark" onClick={handleDelete}>
          Avboka
        </Button>
        <Button variant="secondary" onClick={props.handleCloseModal}>
          Stäng
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
OpenBookingDeleteModal.propTypes = {
  instance: PropTypes.number.isRequired,
  slotId: PropTypes.object.isRequired,
  slots: PropTypes.array.isRequired,
  showModal: PropTypes.bool.isRequired,
  reloadData: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
};
export default OpenBookingDeleteModal;
