import { useState } from "react";

const useReloadData = () => {
  const [reloadFlag, setReloadFlag] = useState(false);

  function reloadData() {
    setReloadFlag(!reloadFlag);
  }
  return { reloadFlag, reloadData };
};

export default useReloadData;
