import { getNowDate, addDaysToDate } from "../lib/dateFunctions";

import { handleResponse, handleError } from "./apiUtils";
//const baseUrl = process.env.REACT_APP_API_URL + "/timeslots/";
const baseUrl =
  "https://uj9bq815f8.execute-api.eu-north-1.amazonaws.com/timeslots";

export function getTimeSlots(instance) {
  const url = baseUrl + "/" + instance;
  return fetch(url).then(handleResponse).catch(handleError);
}

export function getTimeSlotsFromDate(instance) {
  const date = addDaysToDate(getNowDate(), -21); // Get the slots from 3 weeks ago and further
  const url = baseUrl + "/" + instance + "/" + date;
  return fetch(url).then(handleResponse).catch(handleError);
}

export function saveTimeSlot(timeslot) {
  return fetch(baseUrl, {
    method: "POST",
    //    mode: "no-cors",
    headers: { "content-type": "application/json" },
    body: JSON.stringify({ ...timeslot }),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function updateTimeSlot(timeslot) {
  return fetch(baseUrl, {
    method: "PUT",
    //    mode: "no-cors",
    headers: { "content-type": "application/json" },
    body: JSON.stringify({ ...timeslot }),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function deleteTimeSlot(props) {
  const url =
    baseUrl + "/" + props.instance + "/" + encodeURIComponent(props.dateTime);
  return fetch(url, {
    method: "DELETE",
  })
    .then(handleResponse)
    .catch(handleError);
}
