import React, { useState, useEffect } from "react";
import { getNowDate } from "../../lib/dateFunctions";
import Header from "../common//Header";
import Footer from "../common/Footer";
import TimeDateSheet from "./TimeDateSheet";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";

function BookingPage(props) {
  let params = useParams();
  const instance = parseInt(params.instance, 10);
  const [selectedDate, setSelectedDate] = useState(getNowDate());
  const [configuration, setConfiguration] = useState({});

  useEffect(() => {
    props.setInstance(parseInt(params.instance, 10));
  }, [props, params.instance]);

  // Set configuration for the selected instance
  useEffect(() => {
    const result = props.configData.filter((e) => e.instance === instance);
    let _configuration = {};
    if (result.length) {
      _configuration = result[0];
      setConfiguration(_configuration);
    }
  }, [props, instance]);

  return (
    <div className="page-container">
      <Header
        headerTitle={
          configuration.instanceTitle
            ? configuration.instanceTitle + " Bokning " + configuration.name
            : ""
        }
        userFirstName={props.userData.firstName}
        userStatus={props.userData.status}
        userAdmin={props.userData.admin}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        configData={props.configData}
      />
      <TimeDateSheet
        instance={instance}
        configuration={configuration}
        userData={props.userData}
        setUserData={props.setUserData}
        selectedDate={selectedDate}
      />
      <Footer pageFooterText={configuration.instanceName || ""} />
    </div>
  );
}
BookingPage.propTypes = {
  userData: PropTypes.object.isRequired,
  configData: PropTypes.array.isRequired,
  setInstance: PropTypes.func.isRequired,
  setUserData: PropTypes.func.isRequired,
};
export default BookingPage;
