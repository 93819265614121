import React from "react";
import Img_BookingHistoryMenu from "./img/BookingHistoryMenu.png";
import Img_BookingHistoryPage from "./img/BookingHistoryPage.png";

function BookingHistory(props) {
  return (
    <>
      <h3>Bokningshistorik</h3>
      <p>
        För att se din bokningshistorik går du till menyn och väljer{" "}
        <em>Bokningshistorik</em>.
      </p>
      <img src={Img_BookingHistoryMenu} alt="Meny: Bokningshistorik" />
      <p>
        Här visas de bokningar du har gjort som användare. Innevarande och
        föregående år visas och boknigarna är uppdelade per häst man har bokat.
      </p>
      <img src={Img_BookingHistoryPage} alt="Sida för bokningshistorik" />
    </>
  );
}
export default BookingHistory;
