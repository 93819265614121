import React from "react";
import Img_LoginMenu from "./img/LoginMenu.png";
import Img_LoginPage from "./img/LoginPage.png";

function Login(props) {
  return (
    <>
      <h3>Inloggning</h3>
      <p>
        För att logga in i bokningssystemet går du till menyn och väljer{" "}
        <em>Login</em>.
      </p>
      <img src={Img_LoginMenu} alt="Meny: Login" />
      <p>
        Loginsidan visas där du beöver ange användarnamn och epostadress.
        Observera att användarnamnet gör skillnad på STORA och små bokstäver.
        När du trycker på <em>Logga in</em> knappen så loggas du in. Systemet
        kommer ihåg att du är inloggad på en enhet och därför behöver du inte
        logga in varje gång. Om du använder en publik dator bör du aktivt logga
        ut när du varit inloggad.
      </p>
      <img src={Img_LoginPage} alt="Loginsida" />
    </>
  );
}
export default Login;
