import React, { useState, useEffect } from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
import Container from "react-bootstrap/Container";
import SelectionList from "./SelectionList";
import PropTypes from "prop-types";
import Register from "./Register";

function MainPage(props) {
  const [configuration, setConfiguration] = useState({});

  // Set configuration for the selected instance
  useEffect(() => {
    const instance = parseInt(props.instance, 10);
    const result = props.configData.filter((e) => e.instance === instance);
    let _configuration = {};
    if (result.length) {
      _configuration = result[0];
      setConfiguration(_configuration);
    }
  }, [props]);

  return (
    <>
      <Header
        headerTitle={
          configuration.instanceTitle
            ? configuration.instanceTitle + " Ridbanebokning"
            : ""
        }
        userStatus={props.userData.status}
        userFirstName={props.userData.firstName}
        userAdmin={props.userData.admin}
        configData={props.configData}
      />
      <Container fluid="md">
        <h1>Ullstämma ridbanebokning</h1>
        <p>
          Det här är en bokningstjänst för boking av ribanor i Ullstämmastallen
          i Linköping. Här kan du boka nedanstående ridbanor.
        </p>
        <SelectionList configData={props.configData} />
        <Register userData={props.userData} />
        <Footer pageFooterText={configuration.instanceName || ""} />
      </Container>
    </>
  );
}
MainPage.propTypes = {
  userData: PropTypes.object.isRequired,
  configData: PropTypes.array.isRequired,
  instance: PropTypes.number.isRequired,
};
export default MainPage;
