import React, { useState, useEffect, useRef } from "react";
import { getSlotsFromDate } from "../../api/slotsApi";
import { getTimeSlotsFromDate } from "../../api/timeSlotsApi";
import { getActivityTypes } from "../../api/activityTypesApi";
import {
  getWeekStartDate,
  getDateArray,
  getDayName,
  getNowDateTime,
} from "../../lib/dateFunctions";
import useReloadData from "./useReloadData";
import BookModal from "./modal/BookModal";
import Buttons from "./Buttons";
import Activity from "./Activity";
import Open from "./Open";
import Alone from "./Alone";
import Lesson from "./Lesson";
import Comment from "./Comment";
import PropTypes from "prop-types";
import "../../css/modal.css";
import OpenBookingDeleteModal from "./OpenBookingDeleteModal";

function TimeDateSheet(props) {
  const [showModal, setShowModal] = useState(false);
  const [documentVisibility, setDocumentVisibility] = useState();
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const [showOpenModal, setShowOpenModal] = useState(false);
  const handleCloseOpenModal = () => setShowOpenModal(false);
  const handleShowOpenModal = () => setShowOpenModal(true);

  const [slots, setSlots] = useState([]);
  const [timeslots, setTimeSlots] = useState([]);
  const [activityTypes, setActivityTypes] = useState([]);
  const [slotId, setSlotId] = useState({
    date: undefined,
    time: undefined,
    id: undefined,
    name: undefined,
    bookedTimeslot: undefined,
    slotNr: undefined,
    bookedBy: "",
    modalMode: "NEW", // Decides if the Modal shall be rendered for a new booking or update an existing booking. Values are NEW or UPDATE
  });
  const { reloadFlag, reloadData } = useReloadData();

  const positionRef = useRef(0);

  // Handle scroll events. Under development
  const handleScroll = (e) => {
    const x = e.currentTarget.scrollLeft;
    const y = e.currentTarget.offsetWidth;
    const z = e.currentTarget.scrollWidth;

    if (x !== positionRef.current) {
      positionRef.current = x;
      console.log("Horizontal scroll event occurred ...", x, y, z, x + y);
    }
  };

  function handleVisibilityChange(event) {
    setDocumentVisibility(document.visibilityState);
  }

  useEffect(() => {
    if (documentVisibility === "visible") {
      getSlotsFromDate(props.instance).then((_slots) => {
        if (_slots !== undefined && _slots.hasOwnProperty("Items")) {
          setSlots(_slots.Items);
        }
      });
      getTimeSlotsFromDate(props.instance).then((_timeslots) => {
        if (_timeslots !== undefined && _timeslots.hasOwnProperty("Items")) {
          setTimeSlots(_timeslots.Items);
        }
      });
    }
  }, [documentVisibility, props.instance]);

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      window.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  });

  useEffect(() => {
    getSlotsFromDate(props.instance).then((_slots) => {
      setSlots(_slots.Items);
    });
  }, [props.instance, reloadFlag]);

  useEffect(() => {
    getTimeSlotsFromDate(props.instance).then((_timeslots) => {
      setTimeSlots(_timeslots.Items);
    });
  }, [props.instance, reloadFlag]);

  useEffect(() => {
    getActivityTypes(props.instance).then((_activityTypes) => {
      setActivityTypes(_activityTypes.Items);
    });
  }, [props.instance, reloadFlag]);

  const weekStartDate = getWeekStartDate(props.selectedDate);
  const dateArray = getDateArray(weekStartDate, 7);
  const items = dateArray.map((i) => (
    <div className="date-header" key={i}>
      <div>{i}</div>
      <div>{getDayName(i)}</div>
    </div>
  ));

  let content = [];
  if (
    // Wait until configuration data is loaded before rendering the time-date sheet
    props.configuration.earliestTimeToBook === null ||
    props.configuration.latestTimeToBook === null
  )
    return null;
  for (
    let y = props.configuration.earliestTimeToBook;
    y <= props.configuration.latestTimeToBook;
    y++
  ) {
    for (let x = 0; x < 8; x++) {
      if (x === 0) {
        // x = 0 is the first column with cantaines the time values
        content.push(
          <div className="time-item" key={y}>
            {y}-{y + 1}
          </div>
        );
      } else {
        const date = dateArray[x - 1];
        const time = y;
        const dateTimeString = date + ":" + time;
        const filterTimeslot = timeslots.filter(function (e) {
          return e.dateTime === dateTimeString;
        });
        let timeslot = {
          instance: null,
          activity: "",
          dateTime: "",
          alone: false,
          comment: "",
          consecutive: false,
          duration: null,
          lesson: false,
        };
        let activityType = {
          activity: "",
          abbrev: "",
          fontColour: "",
          bgColour: "",
        };

        if (filterTimeslot.length > 0) {
          timeslot = filterTimeslot[0];

          const filterActivityType = activityTypes.filter(function (e) {
            return e.activity === timeslot.activity;
          });
          if (filterActivityType.length > 0) {
            activityType = filterActivityType[0];
          }
        }
        const dateTime = getNowDateTime();
        const bookableSlot =
          dateArray[x - 1] < dateTime.date ||
          (dateArray[x - 1] === dateTime.date && y < dateTime.hour) ||
          timeslot.alone
            ? false
            : true;

        content.push(
          <div className="time-slot-container" key={y + ":" + x}>
            <div className="time-slot">
              <Buttons
                date={date}
                time={time}
                slots={slots}
                nrOfBookingsPerTimeSlot={
                  props.configuration.nrOfBookingsPerTimeSlot
                }
                setSlotId={setSlotId}
                handleShowModal={handleShowModal}
                bookable={bookableSlot}
                userLoginStatus={props.userData.status}
              />
            </div>
            <Lesson lesson={timeslot.lesson} />
            <Open
              date={date}
              time={time}
              slots={slots}
              open={timeslot.open}
              userLoginStatus={props.userData.status}
              setSlotId={setSlotId}
              handleShowOpenModal={handleShowOpenModal}
            />
            <Alone alone={timeslot.alone} />
            <Activity
              activity={timeslot.activity}
              abbreviation={activityType.abbreviation}
              bgColour={activityType.bgColour}
              fontColour={activityType.fontColour}
            />
            <Comment comment={timeslot.comment} />
          </div>
        );
      }
    }
  }

  return (
    <div className="time-container-scroller" onScroll={handleScroll}>
      <div className="time-container">
        <div className="time-item">
          <p>Tid</p>
        </div>
        {items}
        {content}
      </div>
      <BookModal
        instance={props.instance}
        reloadData={reloadData}
        activityTypes={activityTypes}
        slotId={slotId}
        slots={slots}
        timeslots={timeslots}
        userData={props.userData}
        setUserData={props.setUserData}
        footerText={props.configuration.instanceName}
        showModal={showModal}
        handleCloseModal={handleCloseModal}
      />
      <OpenBookingDeleteModal
        instance={props.instance}
        slotId={slotId}
        slots={slots}
        showModal={showOpenModal}
        reloadData={reloadData}
        handleCloseModal={handleCloseOpenModal}
      />
    </div>
  );
}
TimeDateSheet.propTypes = {
  instance: PropTypes.number.isRequired,
  selectedDate: PropTypes.string.isRequired,
  configuration: PropTypes.object.isRequired,
  userData: PropTypes.object.isRequired,
  setUserData: PropTypes.func.isRequired,
};
export default TimeDateSheet;
