import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { checkUserLogin } from "../../api/userApi";
import Header from "../common/Header";
import Footer from "../common/Footer";
import LoginForm from "./LoginForm";
import PropTypes from "prop-types";

function LoginPage(props) {
  const navigate = useNavigate();
  const [configuration, setConfiguration] = useState({});
  const [errors, setErrors] = useState({});
  const [loginUser, setLoginUser] = useState({
    user: "",
    email: "",
  });

  // Set configuration for the selected instance
  useEffect(() => {
    const instance = parseInt(props.instance, 10);
    const result = props.configData.filter((e) => e.instance === instance);
    let _configuration = {};
    if (result.length) {
      _configuration = result[0];
      setConfiguration(_configuration);
    }
  }, [props]);

  function handleChange({ target }) {
    setLoginUser({
      ...loginUser,
      [target.name]: target.value,
    });
  }

  function formIsValid() {
    const _errors = {};

    if (!loginUser.user) _errors.user = "Du har glömt användarnamn";
    if (!loginUser.email) _errors.email = "Du har glömt epostadress";

    setErrors(_errors);
    // Form is valid if the errors object has no properties
    return Object.keys(_errors).length === 0;
  }

  function handleSubmit(event) {
    event.preventDefault();
    if (!formIsValid()) return;
    loginUser.email = loginUser.email.toLowerCase();
    checkUserLogin(loginUser).then((response) => {
      if (response.hasOwnProperty("status")) {
        if (response.status === "SUCCESS") {
          const userData = response;
          userData.status = "LOGGED_IN";
          localStorage.setItem("userId", userData.userid);
          props.setUserData(userData);
          props.setReloadUserData(!props.reloadUserData);
          toast.success("Inloggad.", {
            autoClose: 2000,
            //onClose: () => navigate("/", { replace: true }),
            //      onClose: () => history.goBack(),
            onClose: () => navigate("/"),
          });
        } else if (response.status === "EMAIL_NOT_VERIFIED") {
          toast.warn("Din epostadress är inte verifierad.", {
            autoClose: 6000,
          });
        } else if (response.status === "INVALID") {
          toast.warn("Användarnamn eller epostadress finns inte registrerad.", {
            autoClose: 6000,
          });
        } else if (response.status === "ERROR") {
          toast.error(
            "Ett fel inträffade vid inloggnignen. Försök gärna igen.",
            { autoClose: 6000 }
          );
        } else {
          toast.error(
            "Ett okänt fel inträffade vid inloggnignen. Försök gärna igen.",
            { autoClose: 6000 }
          );
        }
      }
    });
  }

  return (
    <>
      <Header
        headerTitle={
          configuration.instanceTitle
            ? configuration.instanceTitle + " Inloggning"
            : ""
        }
        configData={props.configData}
      />
      <LoginForm
        errors={errors}
        loginUser={loginUser}
        onChange={handleChange}
        onSubmit={handleSubmit}
      />
      <Footer pageFooterText={configuration.instanceName || ""} />
    </>
  );
}
LoginPage.propTypes = {
  configData: PropTypes.array.isRequired,
  instance: PropTypes.number.isRequired,
};
export default LoginPage;
