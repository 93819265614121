import React from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

function ConfigurationForm(props) {
  const navigate = useNavigate();
  return (
    <Container fluid="md">
      <br />
      <Form onSubmit={props.onSubmit}>
        <Form.Group className="mb-3" controlId="formBasicTitle">
          <Form.Label>Välj instans</Form.Label>
          <Form.Control
            as="select"
            name="instance"
            onChange={props.onSelectChange}
          >
            {props.selectOptionsArray}
          </Form.Control>
          <Form.Label>Titel</Form.Label>
          <Form.Control
            type="input"
            placeholder="Titel"
            name="instanceTitle"
            value={props.configuration.instanceTitle}
            onChange={props.onChange}
          />
          {props.errors.instanceTitle && (
            <Form.Text className="alert alert-danger">
              {props.errors.instanceTitle}
            </Form.Text>
          )}
          <Form.Text className="text-muted">
            Ange namn eller förkorning på klubb. Visas överst i menyraden på
            sidorna.
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicName">
          <Form.Label>Klubbnamn</Form.Label>
          <Form.Control
            type="input"
            placeholder="Instansnamn"
            name="instanceName"
            value={props.configuration.instanceName}
            onChange={props.onChange}
          />
          {props.errors.instanceName && (
            <Form.Text className="alert alert-danger">
              {props.errors.instanceName}
            </Form.Text>
          )}
          <Form.Text className="text-muted">Visas som sidfot.</Form.Text>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicName">
          <Form.Label>Instansnamn</Form.Label>
          <Form.Control
            type="input"
            placeholder="Namn"
            name="name"
            value={props.configuration.name}
            onChange={props.onChange}
          />
          {props.errors.name && (
            <Form.Text className="alert alert-danger">
              {props.errors.name}
            </Form.Text>
          )}
          <Form.Text className="text-muted">
            Benämning på det som ska kunna bokas.
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicFirstTime">
          <Form.Label>Första bokningsbara tid</Form.Label>
          <Form.Control
            type="input"
            placeholder="Första bokningstid"
            name="earliestTimeToBook"
            value={props.configuration.earliestTimeToBook}
            onChange={props.onChange}
          />
          {props.errors.earliestTimeToBook && (
            <Form.Text className="alert alert-danger">
              {props.errors.earliestTimeToBook}
            </Form.Text>
          )}
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicLastTime">
          <Form.Label>Sista bokningsbara tid</Form.Label>
          <Form.Control
            type="input"
            placeholder="Sista bokningstid"
            name="latestTimeToBook"
            value={props.configuration.latestTimeToBook}
            onChange={props.onChange}
          />
          {props.errors.latestTimeToBook && (
            <Form.Text className="alert alert-danger">
              {props.errors.latestTimeToBook}
            </Form.Text>
          )}
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicNrOfBooking">
          <Form.Label>Max antal bokningar per tid</Form.Label>
          <Form.Control
            type="input"
            placeholder="Antal bokningar per tid"
            name="nrOfBookingsPerTimeSlot"
            value={props.configuration.nrOfBookingsPerTimeSlot}
            onChange={props.onChange}
          />
          {props.errors.nrOfBookingsPerTimeSlot && (
            <Form.Text className="alert alert-danger">
              {props.errors.nrOfBookingsPerTimeSlot}
            </Form.Text>
          )}
        </Form.Group>
        <Button variant="dark" onClick={props.onShowActivityModal}>
          Ändra Aktivitetslista
        </Button>
        <div className="button-container">
          <Button variant="dark" type="submit" name="register" value="save">
            Spara
          </Button>
          <Button
            variant="secondary"
            name="abort"
            value="abort"
            onClick={() => navigate(-1)}
          >
            Avbryt
          </Button>
        </div>
      </Form>
    </Container>
  );
}

export default ConfigurationForm;
