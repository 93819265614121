import { handleResponse, handleError } from "./apiUtils";
const baseUrl =
  "https://uj9bq815f8.execute-api.eu-north-1.amazonaws.com/configuration";

export function getConfiguration(instance) {
  const url = baseUrl + "/" + instance;
  return fetch(url).then(handleResponse).catch(handleError);
}

export function getAllConfigurations() {
  const url = baseUrl;
  return fetch(url).then(handleResponse).catch(handleError);
}

export function saveConfiguration(configuration) {
  return fetch(baseUrl, {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify({ ...configuration }),
  })
    .then(handleResponse)
    .catch(handleError);
}
