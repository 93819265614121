import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

function TableOfContents(props) {
  if (props.userData.status === "LOGGED_IN") {
    return (
      <>
        <h2>Innehåll</h2>
        <ul>
          <li>
            <Link to={"/help/ShowBooking"}>Visa bokningar</Link>
          </li>
          <li>
            <Link to={"/help/RegisterUser"}>Registrera användare</Link>
          </li>
          <li>
            <Link to={"/help/Login"}>Logga in</Link>
          </li>
          <li>
            <Link to={"/help/Book"}>Boka en tid</Link>
          </li>
          <li>
            <Link to={"/help/BookingHistory"}>Bokningshistorik</Link>
          </li>
          <li>
            <Link to={"/help/ChangeUserDetails"}>Ändra användaruppgifter</Link>
          </li>
          <li>
            <Link to={"/help/ExternalCalendars"}>
              Synkronisera med externa kalendrar
            </Link>
          </li>
          <li>
            <Link to={"/help/Logout"}>Logga ut</Link>
          </li>
        </ul>
      </>
    );
  } else {
    return (
      <>
        <h2>Innehåll</h2>
        <ul>
          <li>
            <Link to={"/help/ShowBooking"}>Visa bokningar</Link>
          </li>
          <li>
            <Link to={"/help/RegisterUser"}>Registrera användare</Link>
          </li>
          <li>
            <Link to={"/help/Login"}>Logga in</Link>
          </li>
        </ul>
      </>
    );
  }
}
TableOfContents.propTypes = {
  userData: PropTypes.object.isRequired,
};
export default TableOfContents;
