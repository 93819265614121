import React from "react";
import PropTypes from "prop-types";

function Lesson(props) {
  if (props.lesson) {
    return (
      <div className="activity-lesson" title="Lektion">
        Lektion
      </div>
    );
  } else {
    return null;
  }
}
Lesson.propTypes = {
  lesson: PropTypes.bool,
};
export default Lesson;
