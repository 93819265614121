import React from "react";
import PropTypes from "prop-types";

function Activity(props) {
  if (props.activity) {
    const style = { backgroundColor: props.bgColour, color: props.fontColour };
    return (
      <div className="activity-type" style={style}>
        <span title={props.activity}>{props.abbreviation}</span>
      </div>
    );
  } else {
    return null;
  }
}
Activity.propTypes = {
  activity: PropTypes.string,
  bgColour: PropTypes.string,
  fontColour: PropTypes.string,
  abbreviation: PropTypes.string,
};
export default Activity;
