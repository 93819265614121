import React from "react";
import PropTypes from "prop-types";

function Alone(props) {
  if (props.alone) {
    return (
      <div className="activity-alone">
        <span title="Ensambokning">E</span>
      </div>
    );
  } else {
    return null;
  }
}
Alone.propTypes = {
  alone: PropTypes.bool,
};
export default Alone;
