import React from "react";
import PropTypes from "prop-types";
import RegisterUser from "./RegisterUser";
import ShowBooking from "./ShowBooking";
import Login from "./Login";
import BookingHistory from "./BookingHistory";
import ChangeUserDetails from "./ChangeuserDetails";
import Logout from "./Logout";
import Book from "./Book";
import ExternalCalendars from "./ExternalCalendars";

function SelectHelpPage(props) {
  if (props.helpPage === "ShowBooking") {
    return <ShowBooking />;
  } else if (props.helpPage === "RegisterUser") {
    return <RegisterUser />;
  } else if (props.helpPage === "Login") {
    return <Login />;
  } else if (props.helpPage === "Book") {
    return <Book />;
  } else if (props.helpPage === "BookingHistory") {
    return <BookingHistory />;
  } else if (props.helpPage === "ChangeUserDetails") {
    return <ChangeUserDetails />;
  } else if (props.helpPage === "ExternalCalendars") {
    return <ExternalCalendars userid={props.userid} />;
  } else if (props.helpPage === "Logout") {
    return <Logout />;
  } else {
    return <p>Välj ett avsnitt i listan ovan.</p>;
  }
}
SelectHelpPage.propTypes = {
  helpPage: PropTypes.string.isRequired,
  userid: PropTypes.number,
};
export default SelectHelpPage;
