import React from "react";
import Img_ShowBookingMenu from "./img/ShowBookingMenu.png";
import Img_BookingPage from "./img/BookingPage.png";
import Img_SmallScreenMenuSelect from "./img/SmallScreenMenuSelect.png";
import Img_BookingPageSmallScreen from "./img/BookingPageSmallScreen.png";

function ShowBooking(props) {
  return (
    <>
      <h3>Visa bokningar</h3>
      <p>
        För att visa bokningar går du in i Meny och väljer den ridbana du vill
        se. Du behöver inte vara inloggad för att se bokningar men för att boka
        behöver du vara registrerad och inloggad.
      </p>
      <img src={Img_ShowBookingMenu} alt="Meny: Visa bokning" />
      <p>
        Bokningarna visas med ett schema per vecka. Du ändrar vecka genom att
        välja med pilarna i övre högra hörnet. Nedan är en blid som föklarar de
        olika fälten.
      </p>
      <img src={Img_BookingPage} alt="Bokningssida" />
      <br />
      <br />
      <p>
        På enheter med små skärmar så måste man klicka på symbolen med tre
        streck <img src={Img_SmallScreenMenuSelect} alt="Symbol för meny" />
        &nbsp;för att visa meny och kunna välja vecka.
      </p>
      <img src={Img_BookingPageSmallScreen} alt="Bokningssida på liten skärm" />
    </>
  );
}
export default ShowBooking;
