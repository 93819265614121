import React from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

function LoginForm(props) {
  const navigate = useNavigate();
  return (
    <Container fluid="md">
      <br />
      <Form onSubmit={props.onSubmit}>
        <Form.Group className="mb-3" controlId="formBasicUser">
          <Form.Label>Användarnamn</Form.Label>
          <Form.Control
            type="input"
            placeholder="Användarnamn"
            name="user"
            value={props.loginUser.user}
            onChange={props.onChange}
          />
          {props.errors.user && (
            <Form.Text className="alert alert-danger">
              {props.errors.user}
            </Form.Text>
          )}
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Epostadress</Form.Label>
          <Form.Control
            type="email"
            placeholder="Epostadress"
            name="email"
            value={props.loginUser.email}
            onChange={props.onChange}
          />
          {props.errors.email && (
            <Form.Text className="alert alert-danger">
              {props.errors.email}
            </Form.Text>
          )}
          <Form.Text className="text-muted">
            Vi delar aldrig din epostadress med någon annan.
          </Form.Text>
        </Form.Group>
        {/*
        <Form.Group className="mb-3" controlId="formBasicCheckbox">
          <Form.Check type="checkbox" label="Check me out" />
        </Form.Group>
        */}
        <Button variant="dark" type="submit">
          Logga in
        </Button>
        <Button
          variant="secondary"
          name="abort"
          value="abort"
          onClick={() => navigate(-1)}
        >
          Avbryt
        </Button>
      </Form>
    </Container>
  );
}
LoginForm.propTypes = {
  loginUser: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};
export default LoginForm;
