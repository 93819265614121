import React from "react";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import PropTypes from "prop-types";

function BookButton(props) {
  return (
    <Button
      variant="outline-dark"
      size="sm"
      key={props.id}
      onClick={function () {
        if (props.userLoginStatus === "LOGGED_IN") {
          props.setSlotId({
            date: props.date,
            time: props.time,
            slotNr: props.slotNr,
            modalMode: "NEW", // Decides if the Modal shall be rendered for a new booking or update an existing booking
          });
          props.handleShowModal();
        } else {
          toast.warn("Du måste vara inloggad för att boka en tid.");
        }
      }}
    >
      {" "}
      Boka
    </Button>
  );
}
BookButton.propTypes = {
  id: PropTypes.string.isRequired,
  userLoginStatus: PropTypes.string,
  date: PropTypes.string.isRequired,
  time: PropTypes.number.isRequired,
  slotNr: PropTypes.number.isRequired,
  setSlotId: PropTypes.func.isRequired,
  handleShowModal: PropTypes.func.isRequired,
};
export default BookButton;
