import React, { useState, useEffect } from "react";
import NameList from "./NameList";
//import Duration from "./Duration";
import ActivityList from "./ActivityList";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { saveSlot, updateSlot, deleteSlot } from "../../../api/slotsApi";
import {
  saveTimeSlot,
  updateTimeSlot,
  deleteTimeSlot,
} from "../../../api/timeSlotsApi";
import { updateUser } from "../../../api/userApi";
import { getTimestamp } from "../../../lib/dateFunctions";
import { addNameToNameList } from "../../../lib/addNameToNameList";
import { toast } from "react-toastify";

const BookModal = (props) => {
  const activityTypes = props.activityTypes;
  const nameList = props.userData.nameList;
  const [bookName, setBookName] = useState("");
  const [openCheckboxState, setOpenCheckboxState] = useState(false); // The openCheckboxState handles the state of the open check box during the first select. It is trasfered to timeslot.open during sublmit. This is because of needed for disable control.
  const [timeslot, setTimeslot] = useState({
    id: "",
    activity: "",
    dateTime: "",
    alone: false,
    comment: "",
    consecutive: false,
    duration: null,
    instance: null,
    lesson: false,
    open: false,
  });

  const [nameListSelect, setNameListSelect] = useState();
  const [newName, setNewName] = useState();
  const [formPartsDisable, setFormPartsDisable] = useState(false);
  const [openBookingTs, setOpenBookingTs] = useState(false);

  useEffect(() => {
    // Set initial value for bookName (the name of that is going to be booked)
    if (props.slotId.name === undefined) {
      // No name is or has been selected in selection list
      if (nameList.length > 0) {
        // There is a list of names in selection list
        // Set bookName to the first value in the selection list
        setBookName(nameList[0][0]);
        setNameListSelect(nameList[0][0]);
        setNewName("");
        setOpenCheckboxState(false);
      }
    } else {
      // There is or has been set a name by the booking button
      // See if the booked name is existing in selction list
      const foundName = nameList.find(function (e) {
        return e[0] === props.slotId.name;
      });
      if (nameList.length > 0) {
        // There are items in the name selection list
        if (foundName === undefined) {
          // We did not find any name that match
          // Set the name as value in the input box
          setBookName(props.slotId.name);
          setNewName(props.slotId.name);
        } else {
          // THe selected name is found in the selection box
          setBookName(props.slotId.name);
          setNameListSelect(props.slotId.name);
          setNewName("");
        }
      } else {
        // Name selection list is empty
        // Select the input box and set the name as value
        setBookName(props.slotId.name);
        setNewName(props.slotId.name);
      }
    }
  }, [
    props.slotId.date,
    props.slotId.time,
    props.slotId.name,
    props.slotId.bookedBy,
    props.slotId.bookedTimestamp,
    nameList,
  ]);

  useEffect(() => {
    // Set id values for timeslot
    const dateTime = props.slotId.date + ":" + props.slotId.time;

    // Set the Id parameters as default values for timeslot
    let updatedTimeslot = {
      ...timeslot,
      instance: parseInt(props.instance, 10),
      dateTime: dateTime,
      alone: false,
      lesson: false,
      open: false,
      comment: "",
      duration: 1,
    };
    if (props.timeslots.length > 0) {
      //If there is already a set timeslot for this timeslot copy the values to timeslot
      const foundTs = props.timeslots.find(function (e) {
        return e.dateTime === dateTime;
      });
      if (foundTs !== undefined) {
        // Inactivate parts of the form when more than one slot is booked.
        updatedTimeslot = foundTs;
        setFormPartsDisable(true);
      } else {
        setFormPartsDisable(false);
      }
      if (foundTs !== undefined && foundTs.open) {
        // If this timeslot is a open booking set the openbookingTs flag
        setOpenBookingTs(true);
      } else {
        setOpenBookingTs(false);
      }
    }
    setTimeslot(updatedTimeslot);
  }, [props.slotId.date, props.slotId.time, props.timeslots]); // eslint-disable-line react-hooks/exhaustive-deps

  // Set default activity in list to the first value if no activity exists for this slot.
  if (timeslot.activity === "" && activityTypes.length > 0) {
    const defaultActivityType = activityTypes[0].activity;
    const updatedTImeslot = { ...timeslot, activity: defaultActivityType };
    setTimeslot(updatedTImeslot);
  }

  function handleLessonChange(event) {
    const updatedTImeslot = { ...timeslot, lesson: !timeslot.lesson };
    setTimeslot(updatedTImeslot);
  }

  function handleAloneChange(event) {
    const updatedTImeslot = { ...timeslot, alone: !timeslot.alone };
    setTimeslot(updatedTImeslot);
  }

  function handleOpenChange(event) {
    setOpenCheckboxState(!openCheckboxState);
  }

  function handleNewNameChange(event) {
    setNewName(event.target.value);
    if (event.target.value) {
      setNameListSelect("");
    } else {
      if (nameList.length > 0) {
        setNameListSelect(nameList[0][0]);
      }
    }
  }

  function handleSlotChange(event) {
    if (event.target.name === "name") {
      setBookName(event.target.value);
      setNameListSelect(event.target.value);
      setNewName("");
    }
  }

  function handleTimeslotChange(event) {
    const updatedTImeslot = {
      ...timeslot,
      [event.target.name]: event.target.value,
    };
    setTimeslot(updatedTImeslot);
  }

  // Handler for submit of the Modal
  function handleSubmit(event) {
    event.preventDefault();

    // Handle values from the new name input text box
    let name = bookName;
    if (newName) {
      name = newName;
    } else if (nameList.length === 0) {
      //There is no selection list and the inout box is empty but still the form is submitted.
      toast.warn("Du försöker spara ett tomt namn!");
      return;
    }

    // Handle Save and Update of booking
    if (props.slotId.id) {
      // Id exists. Update booking
      const updatedBy =
        props.userData.firstName +
        " " +
        props.userData.lastName +
        ", " +
        props.userData.phoneNumber;

      const slot = {
        instance: parseInt(props.instance, 10),
        id: props.slotId.id,
        date: props.slotId.date,
        time: props.slotId.time,
        name: name,
        updatedBy: updatedBy,
      };

      updateSlot(slot).then((response) => {
        let status = "";
        if (response.status === "SUCCESS") {
          status = "SUCCESS";
          if (props.slotId.slotNr === 1) {
            // First slot also update timeslot values.
            updateTimeSlot(timeslot).then((response) => {
              if (response.status === "SUCCESS") {
                status = "SUCCESS";
                props.reloadData();
              } else {
                status = "ERROR";
              }
            });
          } else {
            props.reloadData();
          }
        } else {
          status = "ERROR";
        }

        status === "SUCCESS"
          ? toast.success("Bokningen är uppdaterad.")
          : toast.error(
              "Något gick fel vid uppdatering av bokningen. Försök gärna igen."
            );
      });

      // Update the name list with the updated name
      const updatedNameList = addNameToNameList(props.userData.nameList, name);
      const updatedUser = {
        ...props.userData,
        nameList: updatedNameList,
      };
      props.setUserData(updatedUser);
      const udatedNamelist = {
        userid: updatedUser.userid,
        nameList: updatedUser.nameList,
      };
      updateUser(udatedNamelist);
    } else {
      // Id don't exist. Create Id and save a new booking

      // Check if the name is already booked on this slot.
      const nameAlredyBooked = props.slots.find(function (e) {
        return (
          e.date === props.slotId.date &&
          e.time === props.slotId.time &&
          e.name === name
        );
      });
      if (nameAlredyBooked !== undefined) {
        toast.error(name + " är redan bokad på den här tiden.");
        return;
      }

      const bookedBy =
        props.userData.firstName +
        " " +
        props.userData.lastName +
        ", " +
        props.userData.phoneNumber;

      const timestamp = getTimestamp();
      const id = props.slotId.date + ":" + props.slotId.time + ":" + timestamp;
      const slot = {
        instance: parseInt(props.instance, 10),
        id: id,
        date: props.slotId.date,
        time: props.slotId.time,
        name: name,
        bookedTimestamp: timestamp,
        bookedBy: bookedBy,
        bookedByUserid: props.userData.userid,
      };

      // Handle open bookings (Bookings without any  horse name for lessons etc.)
      if (openCheckboxState && !openBookingTs) {
        const updatedTImeslot = { ...timeslot, open: true };
        setTimeslot(updatedTImeslot);
        saveTimeSlot(updatedTImeslot).then((response) => {
          if (response.status === "SUCCESS") {
            //status = "SUCCESS";
            toast.success("Bokningen är sparad.");
          } else {
            //status = "ERROR";
            toast.error("Något gick fel vid bokningen. Försök gärna igen.");
          }
          props.reloadData();
        });
        props.handleCloseModal();
        return;
      }

      let status = "";
      saveSlot(slot).then((response) => {
        if (response.status === "SUCCESS") {
          status = "SUCCESS";
          if (props.slotId.slotNr === 1) {
            // First booking on this time and date. Also save a timeslot object.
            saveTimeSlot(timeslot).then((response) => {
              if (response.status === "SUCCESS") {
                status = "SUCCESS";
              } else {
                status = "ERROR";
              }
              props.reloadData();
            });
          } else {
            props.reloadData();
          }
        } else {
          status = "ERROR";
        }

        status === "SUCCESS"
          ? toast.success("Bokningen är sparad.")
          : toast.error("Något gick fel vid bokningen. Försök gärna igen.");
      });
      // Update the name list with the updated name
      const updatedNameList = addNameToNameList(props.userData.nameList, name);
      const updatedUser = {
        ...props.userData,
        nameList: updatedNameList,
      };
      props.setUserData(updatedUser);
      const udatedNamelist = {
        userid: updatedUser.userid,
        nameList: updatedUser.nameList,
      };
      updateUser(udatedNamelist);
    }
    props.handleCloseModal();
  }

  function handleDelete() {
    const instance = parseInt(props.instance, 10);
    const id = props.slotId.id;

    deleteSlot({ instance, id }).then((response) => {
      if (props.slotId.slotNr === 1 && !openBookingTs) {
        // First booking on this time and date but not a open booking. Also delete the timeslot object.
        const dateTime = timeslot.dateTime;
        deleteTimeSlot({ instance, dateTime }).then((response) => {
          props.reloadData();
        });
      } else {
        props.reloadData();
      }
      toast.success("Tiden är avbokad.");
    });
    props.handleCloseModal();
  }

  return (
    <Modal show={props.showModal} onHide={props.handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>
          {props.slotId.modalMode === "UPDATE" ? "Ändra bokning" : "Boka"}
          <h6>{props.slotId.date}</h6>
          <h6>
            {String(props.slotId.time).padStart(2, "0") +
              ":00-" +
              String(props.slotId.time + 1).padStart(2, "0") +
              ":00"}
          </h6>
          {props.slotId.bookedBy ? (
            <h6>
              <small>Bokad av {props.slotId.bookedBy}</small>
            </h6>
          ) : null}
          {props.slotId.updatedBy ? (
            <h6>
              <small>Ändrad av {props.slotId.updatedBy}</small>
            </h6>
          ) : null}
        </Modal.Title>
      </Modal.Header>
      <Form>
        <Modal.Body className="show-grid">
          <Container>
            <Row>
              <Col>
                <NameList
                  nameList={nameList}
                  name={nameListSelect}
                  newName={newName}
                  disabled={
                    (!openBookingTs && timeslot.open) || openCheckboxState
                  }
                  onChange={handleSlotChange}
                  onNewNameChange={handleNewNameChange}
                />
                <br />
                <fieldset className="noBorder" disabled={formPartsDisable}>
                  <Form.Group className="mb-3" controlId="open">
                    <Form.Check
                      type="checkbox"
                      id="open"
                      name="open"
                      label="Öppen bokning"
                      onChange={handleOpenChange}
                      //checked={timeslot.open}
                      checked={openCheckboxState || timeslot.open}
                    />
                    <Form.Text className="text-muted">
                      Använd öppen bokning om du vill boka tid utan att boka
                      häst. Tex vid lektion eller öppen bana. Hästar kan sen
                      bokas på tiden.
                    </Form.Text>
                  </Form.Group>
                </fieldset>
              </Col>
              <Col>
                <fieldset
                  className="noBorder"
                  disabled={
                    (formPartsDisable && !(props.slotId.slotNr === 1)) ||
                    (formPartsDisable && timeslot.open)
                  }
                >
                  <ActivityList
                    activityTypes={activityTypes}
                    activity={timeslot.activity}
                    onChange={handleTimeslotChange}
                  />
                  <Form.Group className="mb-3" controlId="checkboxes">
                    <Form.Check
                      type="checkbox"
                      id="lesson"
                      name="lesson"
                      label="Lektion"
                      onChange={handleLessonChange}
                      checked={timeslot.lesson}
                    />
                    <Form.Check
                      type="checkbox"
                      id="alone"
                      name="alone"
                      label="Ensambokning"
                      onChange={handleAloneChange}
                      checked={timeslot.alone}
                    />
                  </Form.Group>
                  <Form.Control
                    type="input"
                    placeholder="Kommentar"
                    name="comment"
                    value={timeslot.comment || ""}
                    onChange={handleTimeslotChange}
                  />
                </fieldset>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          {props.slotId.modalMode === "UPDATE" ? (
            <>
              <Button variant="dark" type="submit" onClick={handleSubmit}>
                Ändra
              </Button>
              <Button variant="outline-dark" onClick={handleDelete}>
                Avboka
              </Button>
            </>
          ) : (
            <Button variant="dark" type="submit" onClick={handleSubmit}>
              Boka
            </Button>
          )}
          <Button variant="secondary" onClick={props.handleCloseModal}>
            Stäng
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default BookModal;
