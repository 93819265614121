import React from "react";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";

function NameList(props) {
  const nameList = props.nameList;

  return (
    <>
      <h5>Häst</h5>
      <fieldset className="noBorder" disabled={props.disabled}>
        {nameList
          .sort(function (a, b) {
            return b[1] - a[1];
          })
          .map((i) => (
            <Form.Check
              type="radio"
              id={i[0]}
              key={i[0]}
              name="name"
              label={i[0] || ""}
              value={i[0] || ""}
              checked={props.name === i[0]}
              onChange={props.onChange}
            />
          ))}

        <Form.Control
          type="input"
          key="newName"
          placeholder="Ny häst"
          name="newName"
          value={props.newName || ""}
          onChange={props.onNewNameChange}
        />
      </fieldset>
    </>
  );
}
NameList.propTypes = {
  nameList: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  newName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onNewNameChange: PropTypes.func.isRequired,
};
export default NameList;

// TODO.
// Change nameList to object instead of array
