import React, { useState, useEffect } from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
import UsersTable from "./UsersTable";
import Container from "react-bootstrap/Container";
import { toast } from "react-toastify";
import { getUsers, updateUser, deleteUser } from "../../api/userApi";
import PropTypes from "prop-types";

function AdminUsersPage(props) {
  const [configuration, setConfiguration] = useState({});
  const [usersData, setUsersData] = useState([]);
  const [reloadUsersData, setReloadUsersData] = useState(false);

  // Set configuration for the selected instance
  useEffect(() => {
    const instance = parseInt(props.instance, 10);
    const result = props.configData.filter((e) => e.instance === instance);
    let _configuration = {};
    if (result.length) {
      _configuration = result[0];
      setConfiguration(_configuration);
    }
  }, [props]);

  // Effect to get all users
  useEffect(() => {
    getUsers().then((_users) => {
      if (_users.hasOwnProperty("Items")) {
        setUsersData(_users.Items);
      }
    });
  }, [reloadUsersData]);

  // Handle change of admin and boxOwner check boxes and store the change into the database
  function handleChange(event) {
    const userid = parseInt(event.target.value, 10);
    const user = usersData.filter((e) => e.userid === userid)[0];
    let _user = { userid: userid };
    if (user.userid && event.target.name === "admin") {
      _user.admin = !user.admin;
      updateUser(_user).then((response) =>
        setReloadUsersData(!reloadUsersData)
      );
    }
    if (user.userid && event.target.name === "boxOwner") {
      _user.boxOwner = !user.boxOwner;
      updateUser(_user).then((response) =>
        setReloadUsersData(!reloadUsersData)
      );
    }
  }

  function handleDelete(event) {
    const userid = event.target.value;
    const user = event.target.name;
    if (window.confirm("Vill du radera användare " + user)) {
      deleteUser({ userid }).then((response) => {
        toast.success("Raderade användare: " + user);
        setReloadUsersData(!reloadUsersData);
      });
    }
  }

  if (!props.userData.admin) return null; //Block the page if you are not admin

  return (
    <>
      <Header
        headerTitle={
          configuration.instanceTitle
            ? configuration.instanceTitle + " Användare"
            : ""
        }
        userStatus={props.userData.status}
        userFirstName={props.userData.firstName}
        userAdmin={props.userData.admin}
        configData={props.configData}
      />
      <Container fluid="md">
        <br />
        <UsersTable
          usersData={usersData}
          instance={props.instance}
          handleChange={handleChange}
          handleDelete={handleDelete}
        />
        <Footer pageFooterText={configuration.instanceName || ""} />
      </Container>
    </>
  );
}
AdminUsersPage.propTypes = {
  userData: PropTypes.object.isRequired,
  configData: PropTypes.array.isRequired,
  instance: PropTypes.number.isRequired,
};
export default AdminUsersPage;
