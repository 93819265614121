const { add, startOfWeek, format, parse, getISOWeek } = require("date-fns");
//const {utcToZonedTime} = require('date-fns-tz');
//const sv = require('date-fns/locale/sv');

export function getNowObj() {
  //	return format(new Date(), 'yyyy-MM-dd', {locale: sv});
  const year = format(new Date(), "yyyy");
  return {
    date: format(new Date(), "yyyy-MM-dd"),
    year: year,
    month: format(new Date(), "MM"),
    day: format(new Date(), "dd"),
    hour: format(new Date(), "H"),
  };
}
export function getNowDateTime() {
  //	return format(new Date(), 'yyyy-MM-dd', {locale: sv});
  return {
    date: format(new Date(), "yyyy-MM-dd"),
    hour: format(new Date(), "H"),
  };
}

export function getNowDate() {
  //	return format(new Date(), 'yyyy-MM-dd', {locale: sv});
  return format(new Date(), "yyyy-MM-dd");
}

export function getYear(inputDate) {
  const pattern = /^\d{4}-\d{2}-\d{2}/;
  if (!pattern.test(inputDate)) {
    return undefined;
  }
  const fnsDate = parse(inputDate, "yyyy-MM-dd", new Date());
  return format(fnsDate, "yyyy");
}

export function getWeekNr(inputDate) {
  const pattern = /^\d{4}-\d{2}-\d{2}/;
  if (!pattern.test(inputDate)) {
    return undefined;
  }
  const fnsDate = parse(inputDate, "yyyy-MM-dd", new Date());
  return getISOWeek(fnsDate, { weekStartsOn: 1, firstWeekContainsDate: 4 });
}

export function getWeekStartDate(inputDate) {
  const pattern = /^\d{4}-\d{2}-\d{2}/;
  if (!pattern.test(inputDate)) {
    return undefined;
  }
  const fnsDate = parse(inputDate, "yyyy-MM-dd", new Date());
  const startDateOfWeek = startOfWeek(fnsDate, { weekStartsOn: 1 });
  return format(startDateOfWeek, "yyyy-MM-dd");
}

export function getDayName(inputDate) {
  const pattern = /^\d{4}-\d{2}-\d{2}/;
  if (!pattern.test(inputDate)) {
    return undefined;
  }
  const dnsDate = parse(inputDate, "yyyy-MM-dd", new Date());
  let dayName = format(dnsDate, "EEEE");
  const dayNameSe = dayName
    .replace("Monday", "Måndag")
    .replace("Tuesday", "Tisdag")
    .replace("Wednesday", "Onsdag")
    .replace("Thursday", "Torsdag")
    .replace("Friday", "Fredag")
    .replace("Saturday", "Lördag")
    .replace("Sunday", "Söndag");
  //const dayName = format(dnsDate, 'EEEE', {locale: sv});
  return dayNameSe;
  //	return dayName.charAt(0).toUpperCase() + string.slice(1); // Upper case version
}

export function getMonthName(month) {
  const monthNames = [
    "Jan",
    "Feb",
    "Mars",
    "Apr",
    "Maj",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Okt",
    "Nov",
    "Dec",
  ];
  return monthNames[month - 1];
}

export function addDaysToDate(inputDate, days) {
  const pattern = /^\d{4}-\d{2}-\d{2}/;
  if (!pattern.test(inputDate)) {
    return undefined;
  }
  const fnsDate = parse(inputDate, "yyyy-MM-dd", new Date());
  return format(add(fnsDate, { days: days }), "yyyy-MM-dd");
}

export function getDateArray(inputDate, length) {
  const pattern = /^\d{4}-\d{2}-\d{2}/;
  if (!pattern.test(inputDate)) {
    return undefined;
  }
  const weekDaysArray = new Array(length);
  const fnsDate = parse(inputDate, "yyyy-MM-dd", new Date());
  for (let x = 0; x < weekDaysArray.length; x++) {
    weekDaysArray[x] = format(add(fnsDate, { days: x }), "yyyy-MM-dd");
  }
  return weekDaysArray;
}

export function getTimestamp() {
  return format(new Date(), "T");
}

export function timestampToDate(inputDate) {
  let dateTime = format(inputDate, "yyyy-MM-dd HH:mm:ss");
  return dateTime;
}
