import React from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
import Container from "react-bootstrap/Container";
import PropTypes from "prop-types";

function VerifyEmailPage(props) {
  const headerTitle = "Ullstämma";
  const pageFooterText = "2022 Ullstämmma ridbanebokningar";
  return (
    <>
      <Header
        headerTitle={headerTitle}
        userStatus={props.userData.status}
        userFirstName={props.userData.firstName}
        configData={props.configData}
      />
      <Container fluid="md">
        <h1>Registrering</h1>
        <p>Tack för att du har registrerat dig hos oss.</p>
        <p>
          Innan du kan logga in så vill vi verifiera din emailadress. Vi har
          skickat ett mail till mailadressen du angav. Leta upp mailet och
          klicka på länken i mailet. Efter det kan du börja använda bokningen.
        </p>
        <p>
          Tack!
          <br />
          Ullstämmastallens ridbanebokning.
        </p>
        <Footer pageFooterText={pageFooterText} />
      </Container>
    </>
  );
}
VerifyEmailPage.propTypes = {
  userData: PropTypes.object.isRequired,
  configData: PropTypes.array.isRequired,
};
export default VerifyEmailPage;
