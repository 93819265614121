import React from "react";
import Img_ChangeUserDetailsMenu from "./img/ChangeUserDetailsMenu.png";

function ChangeUserDetails(props) {
  return (
    <>
      <h3>Ändra användaruppgifter</h3>
      <p>
        För att ändra användaruppgifter går du till menyn och väljer{" "}
        <em>Ändra användaruppgifter</em>.
      </p>
      <img
        src={Img_ChangeUserDetailsMenu}
        alt="Meny: Ändra användaruppgifter"
      />
      <p>Här kan du ändra dina användaruppgifter såsom:</p>
      <ul>
        <li>Användarnamn</li>
        <li>Epostadress</li>
        <li>Förnamn</li>
        <li>Efternamn</li>
        <li>Telefonnummer</li>
      </ul>
      <p>
        Om du ändrar dina epostadress så skickar vi ett verifieringsmail till
        den nya adressen och du måste klicka på länken i mailet innan du kan
        logga in igen.
      </p>
      <p>
        ICAL-länken längst ner på sidan kan användas för att synkronisera dina
        bokningar med externa kalendrar som Microsoft Outlook, Google Kalender
        och iPhone kalender.
      </p>
    </>
  );
}
export default ChangeUserDetails;
